import { useNavigate } from 'react-router-dom';
import { CaretLeft } from '@phosphor-icons/react';
import styled from 'styled-components';
import { useMemo } from 'react';
import { HoverBorderButton, TitleButton } from '../elements/Button';
import { theme } from '../utility';

const StyledBackButton = styled(HoverBorderButton)`
  min-width: 40px;
`;

export function BackButton() {
  const navgiate = useNavigate();
  const moveBack = () => {
    navgiate(-1);
  };
  return (
    <StyledBackButton
      className="icon-btn mr-3"
      onClick={moveBack}
      type="button"
    >
      <CaretLeft size={20} />
    </StyledBackButton>
  );
}

const SmallBackBtn = styled(TitleButton)`
  width: 30px !important;
  padding: 0px !important;
`;

export function TitleBackButton({ url = null }) {
  const navgiate = useNavigate();
  const moveBack = () => {
    if (url) {
      navgiate(url);
    } else {
      navgiate(-1);
    }
  };

  return (
    <SmallBackBtn size="mini" className="mr-2" onClick={moveBack} type="button">
      <CaretLeft color={theme.colors.white} size={15} />
    </SmallBackBtn>
  );
}

const AllocationProgressBarContainer = styled.div`
  margin: 0.8rem auto;
  position: relative;
  height: 8px;
  width: 100%;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.colors.box};
  &.lighterBg {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const AllocationProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ progress }) => progress}%;
  height: inherit;
  border-radius: inherit;
  background-color: ${({ bgColor }) => bgColor};
`;

export function ProgressBar({ progress, className = '', customColor }) {
  const bgColor = useMemo(() => {
    if (customColor) {
      return customColor;
    }
    return progress === 100 ? theme.colors.green : theme.colors.danger;
  }, [progress, customColor]);

  return (
    <AllocationProgressBarContainer className={className}>
      <AllocationProgressBar bgColor={bgColor} progress={progress} />
    </AllocationProgressBarContainer>
  );
}
