import { Barcode, Cube, Package, Tray, Truck } from '@phosphor-icons/react';
import styled from 'styled-components';
import { useMemo } from 'react';
import {
  ColoredPill,
  HeaderText,
  HoverBorderButton,
  MuteBorder,
  MuteText,
  ShadowBox,
} from '../../../elements';
import { CommonUtility, theme } from '../../../utility';
import { ItemImage, LoaderBar } from '../../../components';

const ItemsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const TitleContainer = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;

export function PackingDetailItems({
  data,
  items,
  isPallet,
  packItem,
  fulfillItem,
  loading,
}) {
  const pendingItems = useMemo(() => {
    if (CommonUtility.isValidArray(items)) {
      return items?.filter(x =>
        isPallet ? x.status !== 'shipped' : x.status !== 'packed',
      );
    }
    return [];
  }, [items, isPallet]);

  return (
    <ShadowBox className="col-6">
      {loading && <LoaderBar />}
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <Tray size={24} color={theme.colors.grey} />
          <HeaderText className="mx-1">
            {isPallet ? 'Pallet' : 'Tote'}
          </HeaderText>
          <ColoredPill className="ml-2" color={theme.colors.grey}>
            <Barcode />
            {data?.toteNumber || data?.palletNumber}
          </ColoredPill>
        </div>
        <div className="d-flex align-items-center">
          <Cube color={theme.colors.grey} size={20} />
          <MuteText className="ml-2">{pendingItems?.length} Items</MuteText>
        </div>
      </div>
      <ItemsContainer>
        {pendingItems?.map((item, index) => (
          <>
            <div
              key={item._id}
              className="d-flex align-items-center justify-content-between"
            >
              <div className="d-flex align-items-center">
                <ItemImage
                  imageKey="p_images"
                  displayImageKey="display_image"
                  item={item?.productId}
                  size="mini"
                />
                <div className="d-flex flex-column ml-2">
                  <TitleContainer>{item?.productId?.p_title}</TitleContainer>
                  <div className="d-flex">
                    <MuteText>SKU</MuteText>
                    <span className="ml-2">{item?.productId?.p_sku}</span>
                  </div>
                  <div className="d-flex">
                    <MuteText>UPC</MuteText>
                    <span className="ml-2">{item?.productId?.upc}</span>
                  </div>
                </div>
              </div>
              {isPallet ? (
                <HoverBorderButton
                  className="flex-btn px-3"
                  onClick={() => fulfillItem(item?.productId?.upc)}
                >
                  <Truck className="mr-1" size={17} /> Fulfill
                </HoverBorderButton>
              ) : (
                <HoverBorderButton
                  onClick={() => packItem(item?.productId?.upc)}
                  className="flex-btn px-3"
                >
                  <Package className="mr-1" size={17} /> Pack
                </HoverBorderButton>
              )}
            </div>
            {index !== pendingItems?.length - 1 && <MuteBorder />}
          </>
        ))}
      </ItemsContainer>
    </ShadowBox>
  );
}
