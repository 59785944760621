import { useParams, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import { BodyContainer } from '../../../../elements';
import { OrderDetailHook } from '../../../../hooks/order';
import {
  CustomerDeatils,
  FulfillOrderPopup,
  LabelDetails,
  OrderDetailsHeader,
  PickingList,
} from '../../../../page-components/order';
import { OrderService, ToastMessage } from '../../../../utility';

export function OrderDetailScreen() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const providerId = searchParams.get('providerId');
  const {
    data,
    refresh,
    loading: orderLoading,
  } = OrderDetailHook(id, providerId);

  const [openFulfill, setOpenFulfill] = useState(false);
  const [loading, setLoading] = useState(false);

  const fulfillOrder = async () => {
    try {
      setLoading(true);
      const payload = {
        action: 'fulfill',
      };
      await OrderService.orderAction(id, payload);
      closePopup();
      ToastMessage.success('Order fulfilled successfully.');
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const openPopup = () => {
    setOpenFulfill(true);
  };

  const closePopup = () => {
    setOpenFulfill(false);
  };

  return (
    <BodyContainer className="no-header">
      <OrderDetailsHeader
        loading={orderLoading}
        openPopup={openPopup}
        data={data}
        showAction
      />
      <CustomerDeatils loading={orderLoading} data={data} />
      <div className="row mt-4">
        <PickingList loading={orderLoading} data={data} />
        <LabelDetails loading={orderLoading} data={data} />
      </div>
      <FulfillOrderPopup
        open={openFulfill}
        onClose={closePopup}
        onConfirm={fulfillOrder}
        loading={loading}
      />
    </BodyContainer>
  );
}
