import { useEffect, useState } from 'react';
import { WarehouseService } from '../utility/services/warehouse';
import { CacheUtility, CommonUtility } from '../utility';

export const WarehouseListHook = () => {
  const key = 'warehouse-list';
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await WarehouseService.get();
      CacheUtility.save(key, res, 120);
      setData(res);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const list = CacheUtility.get(key);
    if (CommonUtility.isValidArray(list)) {
      setData(list);
    } else {
      fetchData();
    }
  }, []);

  return { data, loading, error, refresh: fetchData };
};

export const WarehouseDetailHook = id => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await WarehouseService.getById(id);
      setData(res);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error, refresh: fetchData };
};

export const WarehouseShipnodeHook = id => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await WarehouseService.warehouseShipnodes(id);
      setData(res);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id]);

  return { data, loading, error, refresh: fetchData };
};
