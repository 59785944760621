import {
  Calendar,
  CalendarDots,
  ChartLine,
  UserCircle,
} from '@phosphor-icons/react';
import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import {
  BodyContainer,
  HeaderBar,
  MuteText,
  PageTitle,
  TitleBarTitle,
  TitleHeaderBar,
} from '../../elements/Common';
import { ClientHook, FilterHook } from '../../hooks';
import { AppTable } from '../../components/AppTable';
import { AnalyticCards, CompanyRow } from '../../page-components';
import { FilterButton, FilterPill, SearchInput } from '../../components';
import { CommonUtility } from '../../utility/common';
import { DateFormat, DateUtility } from '../../utility/date';
import { StyledButton } from '../../elements';
import { useAuth } from '../../contexts/auth';
import { DesktopMode, MobileMode } from '../../layouts/ResponsiveMedia';

const FilterPillContainer = styled.div`
  position: absolute;
  right: 30px;
  width: calc(60% - 60px);
  margin-top: 5px;
`;

function Header({ filters, finalFilter, updateAllFilter }) {
  const {
    finalValue,
    setFinalValue,
    activeIndex,
    setActiveIndex,
    open,
    setOpen,
    pillFilters,
    removeFilter,
    openRelevantFilter,
  } = FilterHook(filters, finalFilter);
  return (
    <>
      <MobileMode>
        <HeaderBar className="row">
          <div className="col-6 pt-2">
            <PageTitle className="d-flex align-items-center">
              <ChartLine size={25} className="mr-1" /> <span>Analytics</span>
            </PageTitle>
          </div>
          <div className="col-6 row justify-content-end">
            <FilterButton
              setFinalFilter={updateAllFilter}
              filters={filters}
              finalFilter={finalFilter}
              setFinalvalue={setFinalValue}
              finalValue={finalValue}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              open={open}
              setOpen={setOpen}
            />
          </div>
        </HeaderBar>
      </MobileMode>
      <DesktopMode>
        <TitleHeaderBar className="row">
          <div className="col-6">
            <TitleBarTitle className="d-flex align-items-center">
              <ChartLine size={20} className="mr-1 icon" />{' '}
              <span className="mt-1">Analytics</span>
            </TitleBarTitle>
          </div>
          <div className="col-6 row justify-content-end">
            <FilterButton
              setFinalFilter={updateAllFilter}
              filters={filters}
              finalFilter={finalFilter}
              setFinalvalue={setFinalValue}
              finalValue={finalValue}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              open={open}
              setOpen={setOpen}
              titleBtn
            />
          </div>
        </TitleHeaderBar>
      </DesktopMode>
      <FilterPillContainer>
        {pillFilters?.length > 0 && (
          <div className="d-flex align-items-center justify-content-end">
            <MuteText>Filters</MuteText>
            {filters.map(filter => (
              <FilterPill
                data={filter}
                key={filter.name}
                userValue={finalValue}
                removeFilter={removeFilter}
                openRelevantFilter={openRelevantFilter}
              />
            ))}
          </div>
        )}
      </FilterPillContainer>
    </>
  );
}

export function HomeScreen() {
  const [search, setSearch] = useState('');
  const {
    data,
    loading,
    exportCSV,
    exporting,
    updateAllFilter,
    filter: tableFilter,
    exportIndividualCSV,
  } = ClientHook();
  const { user } = useAuth();
  const [searchParams] = useSearchParams();

  const filteredCompanies = useMemo(() => {
    if (!CommonUtility.isValidArray(data.activeCompanies)) return [];

    let companies = data.activeCompanies;

    companies = companies.sort(
      (company1, company2) => company2.mtdOrders - company1.mtdOrders,
    );

    if (!search) {
      return companies;
    }
    return companies.filter(
      x =>
        search.toLowerCase().includes(x.company_name?.toLowerCase()) ||
        x.company_name?.toLowerCase().includes(search?.toLowerCase()),
    );
  }, [data, search]);

  const currentOrgMetadata = useMemo(
    () =>
      user?.organizations?.find(x => x._id === user?.organizationId)
        ?.metadata || {},
    [user],
  );

  const columns = [
    {
      key: 'name',
      text: 'Company & Contact Person',
      textAlign: 'left',
    },
    {
      key: 'status',
      text: 'Status',
    },
    {
      key: 'orders',
      text: 'Monthly Orders',
    },
    {
      key: 'labels',
      text: 'Monthly Labels',
    },
    {
      key: 'label_purchases',
      text: 'Total Label Purchases',
    },
    {
      key: 'commission',
      text: 'Label Commission',
    },
  ];

  const dateOptions = [
    {
      text: 'Today',
      value: 'today',
    },
    {
      text: '7 Days',
      value: '7days',
    },
    {
      text: '30 Days',
      value: '30days',
    },
    {
      text: '45 Days',
      value: '45days',
    },
    {
      text: '90 Days',
      value: '90days',
    },
  ];

  const filters = useMemo(
    () => {
      const query = Object.fromEntries(searchParams.entries());
      const temp = { ...query };
      Object.keys(temp).forEach(key => {
        const final = CommonUtility.tryParseJSONObject(temp[key]);
        if (final) {
          temp[key] = final;
        }
      });

      const defaultValues = {
        ...temp,
      };

      const arr = [
        {
          name: 'startDate',
          label: 'Start Date',
          icon: <Calendar />,
          type: 'date',
          defaultValue: (defaultValues && defaultValues.startDate) || null,
        },
        {
          name: 'endDate',
          label: 'End Date',
          icon: <Calendar />,
          type: 'date',
          defaultValue: (defaultValues && defaultValues.endDate) || null,
        },
        {
          name: 'timeRange',
          label: 'Time Range',
          icon: <CalendarDots />,
          options: dateOptions,
          search: true,
          sorted: false,
          defaultValue: (defaultValues && defaultValues.timeRange) || null,
        },
      ];

      return arr;
    },
    [],
    200,
  );

  return (
    <BodyContainer className="no-header">
      <Header
        filters={filters}
        finalFilter={tableFilter}
        loading={loading}
        updateAllFilter={updateAllFilter}
      />
      <div className="d-flex mt-4">
        <MuteText>Orders From</MuteText>
        <span className="mx-2">
          {DateUtility.formatDate(data?.start, DateFormat.month)}
        </span>
        <MuteText>To</MuteText>
        <span className="ml-2">
          {DateUtility.formatDate(data?.end, DateFormat.month)}
        </span>
      </div>
      <AnalyticCards
        currentOrgMetadata={currentOrgMetadata}
        loading={loading}
        data={data}
      />
      <div className="d-flex justify-content-between mt-4 flex-wrap">
        <div className="d-flex align-items-center mr-4">
          <UserCircle size={27} />
          <h3 className="ml-2 mt-0">Clients</h3>
        </div>
        <div className="d-flex">
          <StyledButton
            className="same-height"
            loading={exporting}
            onClick={exportCSV}
          >
            Export
          </StyledButton>
          <SearchInput
            loading={loading}
            placeholder="Search"
            search={search}
            setSearch={setSearch}
          />
        </div>
      </div>
      <AppTable
        columns={columns}
        loading={loading}
        RowComponent={<CompanyRow exportData={exportIndividualCSV} />}
        data={filteredCompanies}
      />
    </BodyContainer>
  );
}
