import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormGroup } from 'semantic-ui-react';
import { useEffect, useMemo, useState } from 'react';
import {
  CommonUtility,
  PalletService,
  ToastMessage,
} from '../../../../utility';
import { SkupremeModal } from '../../../../components';
import {
  ControlledTextFormField,
  DropdownFormField,
  HoverBorderButton,
  NumberFormField,
  StyledButton,
} from '../../../../elements';
import { WarehouseZonesHook } from '../../../../hooks/zone';

const ZoneSchema = yup.object().shape({
  palletNumber: yup.string().required('Pallet number is Required'),
  zoneId: yup.string().optional(),
  length: yup
    .number()
    .nullable()
    .transform((_, val) => (!isNaN(val) ? Number(val) : null)),
  width: yup
    .number()
    .nullable()
    .transform((_, val) => (!isNaN(val) ? Number(val) : null)),
  height: yup
    .number()
    .nullable()
    .transform((_, val) => (!isNaN(val) ? Number(val) : null)),
  weight: yup
    .number()
    .nullable()
    .transform((_, val) => (!isNaN(val) ? Number(val) : null)),
  weightLimit: yup
    .number()
    .nullable()
    .transform((_, val) => (!isNaN(val) ? Number(val) : null)),
});

export function AddPalletPopup({ refresh, open, onClose, warehouseId, item }) {
  const [loading, setLoading] = useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(ZoneSchema),
    shouldUnregister: true,
  });

  const { data: zones } = WarehouseZonesHook(warehouseId);

  useEffect(() => {
    if (CommonUtility.isValidObject(item)) {
      reset({ ...item, ...(item.dimensions || {}), zoneId: item?.zoneId?._id });
    } else {
      reset({
        palletNumber: '',
        zoneId: '',
        length: '',
        width: '',
        height: '',
        weight: '',
        weightLimit: '',
      });
    }
  }, [open]);

  const zoneOptions = useMemo(() => {
    if (CommonUtility.isValidArray(zones)) {
      return zones?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [zones]);

  const onSubmit = async formData => {
    try {
      setLoading(true);
      let payload = {
        warehouseId,
        palletNumber: formData.palletNumber,
        zoneId: formData.zoneId,
        dimensions: {
          length: formData.length,
          width: formData.width,
          height: formData.height,
          weight: formData.weight,
          weightLimit: formData.weightLimit,
        },
      };

      payload = CommonUtility.removeEmptyFields(payload);

      if (CommonUtility.isValidObject(item)) {
        await PalletService.update(item._id, payload);
      } else {
        await PalletService.add(payload);
      }

      ToastMessage.success('Zone successfully created.');
      onClose();
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SkupremeModal
      size="tiny"
      open={open}
      onClose={onClose}
      title={
        CommonUtility.isValidObject(item) ? 'Edit Pallet' : 'Add New Pallet'
      }
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <ControlledTextFormField
            control={control}
            name="palletNumber"
            label="Pallet Number"
            error={errors.palletNumber}
            hint={errors.palletNumber?.message}
            required
            width={16}
          />
        </FormGroup>
        <FormGroup widths="equal">
          <DropdownFormField
            control={control}
            error={errors.zoneId}
            hint={errors.zoneId?.message}
            name="zoneId"
            label="Zone"
            options={zoneOptions}
            selection
          />
        </FormGroup>
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            error={errors.length}
            hint={errors.length?.message}
            name="length"
            label="Length"
          />
          <NumberFormField
            control={control}
            error={errors.width}
            hint={errors.width?.message}
            name="width"
            label="Width"
          />
          <NumberFormField
            control={control}
            error={errors.height}
            hint={errors.height?.message}
            name="height"
            label="Height"
          />
        </FormGroup>
        <FormGroup widths="equal">
          <NumberFormField
            control={control}
            error={errors.weight}
            hint={errors.weight?.message}
            name="weight"
            label="Weight"
          />
          <NumberFormField
            control={control}
            error={errors.weightLimit}
            hint={errors.weightLimit?.message}
            name="weightLimit"
            label="Weight Limit"
          />
        </FormGroup>
        <div className="d-flex justify-content-end">
          <HoverBorderButton type="button" onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton type="submit" loading={loading} className="ml-1">
            {CommonUtility.isValidObject(item) ? 'Update' : 'Create'}
          </StyledButton>
        </div>
      </Form>
    </SkupremeModal>
  );
}
