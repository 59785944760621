import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Waves } from '@phosphor-icons/react';
import {
  BodyContainer,
  HeaderBar,
  PageTitle,
  TitleBarTitle,
  TitleHeaderBar,
} from '../../../../../elements/Common';
import { TabStyleMenu } from '../../../../../elements/Tab';
import {
  CacheTimes,
  CacheUtility,
  CommonUtility,
  Tabs,
} from '../../../../../utility';
import { WaveListTab } from './list';
import { SortedWaveListTab } from './sortedLists';
import { WarehouseListHook } from '../../../../../hooks';
import { UnAssignedListTab } from './unassigned-list';
import { WaveSearchInput } from '../../../../../page-components/waves/WaveSearch';
import { WarehouseDropdown } from '../../../../../components';
import {
  DesktopMode,
  MobileMode,
  useTabletAndBelowMediaQuery,
} from '../../../../../layouts/ResponsiveMedia';
import { MobileWaveList } from './mobile-list';

function Header({
  warehouseOptions,
  warehouseLoading,
  warehouseId,
  setWarehouseId,
  setSearch,
  isWaveTab,
  defaultWarehouse,
  isMobileDevice,
}) {
  const navigate = useNavigate();
  const handleResultClick = item => {
    navigate(`/app/fulfill/waves/${item._id}`);
  };

  const isMobile = useTabletAndBelowMediaQuery();

  return (
    <>
      <MobileMode>
        <HeaderBar className={`row ${isMobileDevice ? '' : 'tab-layout'}`}>
          <div
            className={`d-flex ${
              isMobile ? 'flex-column col' : 'pt-2 col-9 align-items-center'
            }`}
          >
            <PageTitle className="d-flex align-items-center">
              <Waves size={25} className="mx-2" /> <span>Waves for</span>
            </PageTitle>
            <div className="d-flex align-items-center ml-2">
              <WarehouseDropdown
                warehouseOptions={warehouseOptions}
                warehouseId={warehouseId}
                warehouseLoading={warehouseLoading}
                setWarehouseId={setWarehouseId}
                defaultWarehouseId={defaultWarehouse}
                className="mb-1"
              />
            </div>
          </div>
        </HeaderBar>
      </MobileMode>
      <DesktopMode>
        <TitleHeaderBar className="row align-items-start">
          <div className="d-flex col-8 align-items-start">
            <TitleBarTitle className="d-flex align-items-center mt-1">
              <Waves size={20} className="mr-1 icon" /> <span>Waves for</span>
            </TitleBarTitle>
            <div className="d-flex ml-2">
              <WarehouseDropdown
                warehouseOptions={warehouseOptions}
                warehouseId={warehouseId}
                warehouseLoading={warehouseLoading}
                setWarehouseId={setWarehouseId}
                defaultWarehouseId={defaultWarehouse}
                titleMode
              />
            </div>
          </div>
          {isWaveTab && (
            <div className="col-4 d-flex align-items-center justify-content-end">
              <WaveSearchInput
                onResultClick={handleResultClick}
                setTableSearch={setSearch}
                warehouseId={warehouseId}
                inputClassName="mini"
                size="mini"
              />
            </div>
          )}
        </TitleHeaderBar>
      </DesktopMode>
    </>
  );
}

const WaveContext = createContext();

const TabContainer = styled.div`
  margin-top: 40px;
`;

export function WaveListScreen() {
  const cacheKey = 'wave-list';
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [warehouseId, setWarehouseId] = useState('');
  const [search, setSearch] = useState('');

  const { data: warehouseData, loading: warehouseLoading } =
    WarehouseListHook();

  const warehouseOptions = useMemo(() => {
    if (CommonUtility.isValidArray(warehouseData)) {
      return warehouseData?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [warehouseData]);

  useEffect(() => {
    const cache = CacheUtility.get(cacheKey);
    if (cache?.tabName) {
      tabClicked(cache?.tabName);
    }
  }, []);

  const currentTab = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 5);
    }
    return null;
  }, [pathname]);

  const tabClicked = tabName => {
    navigate(`/app/fulfill/waves/list/${tabName}`);
    CacheUtility.save(cacheKey, { tabName }, CacheTimes.TWO_HOURS);
  };

  const menus = [
    {
      text: 'Building',
      value: Tabs.WavesTabs.building,
    },
    {
      text: 'Ready',
      value: Tabs.WavesTabs.ready,
    },
    {
      text: 'In Progress',
      value: Tabs.WavesTabs.in_progress,
    },
    {
      text: 'Sorting',
      value: Tabs.WavesTabs.sorting,
    },
    {
      text: 'Sorted',
      value: Tabs.WavesTabs.sorted,
    },
    {
      text: 'Unassigned',
      value: Tabs.WavesTabs.unassigned,
    },
  ];

  const isWaveTab = ![
    Tabs.WavesTabs.unassigned,
    Tabs.WavesTabs.sorted,
  ].includes(currentTab);

  const defaultWarehouse = useMemo(
    () =>
      CommonUtility.isValidArray(warehouseData) ? warehouseData[0]._id : null,
    [warehouseData],
  );

  const isMobileDevice = useMemo(() => CommonUtility.isMobileDevice(), []);

  return (
    <WaveContext.Provider
      value={{
        setWarehouseId,
        warehouseId,
        search,
      }}
    >
      <BodyContainer className="no-header">
        <Header
          setWarehouseId={setWarehouseId}
          warehouseId={warehouseId}
          warehouseOptions={warehouseOptions}
          warehouseLoading={warehouseLoading}
          setSearch={setSearch}
          isWaveTab={isWaveTab}
          defaultWarehouse={defaultWarehouse}
          isMobileDevice={isMobileDevice}
        />
        {isMobileDevice ? (
          <MobileWaveList setTableSearch={setSearch} />
        ) : (
          <>
            <TabStyleMenu
              menus={menus}
              currentMenu={currentTab}
              onTabChange={tabClicked}
              className="no-header"
            />
            <TabContainer>
              <Routes>
                <Route path="sorted/*" element={<SortedWaveListTab />} />
                <Route path="unassigned" element={<UnAssignedListTab />} />
                <Route path="*" element={<WaveListTab />} />
              </Routes>
            </TabContainer>
          </>
        )}
      </BodyContainer>
    </WaveContext.Provider>
  );
}

export const useWavesFilterContext = () => useContext(WaveContext);
