import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { BodyContainer } from '../../../../elements';
import { PackingDetailHook } from '../../../../hooks/packing';
import {
  PackingDetailHeader,
  PackingDetailItems,
  PackingDetailPacks,
} from '../../../../page-components/packaging';
import { useBarcodeScanner } from '../../../../hooks/barcode';
import { CommonUtility, ToastMessage } from '../../../../utility';
import { PackagingService } from '../../../../utility/services/packaging';
import { WarehouseListHook } from '../../../../hooks';

export function PickingDetailScreen() {
  const { containerNumber } = useParams();

  const [items, setItems] = useState([]);
  const [currentScannedUPC, setCurrentScannedUPC] = useState(null);
  const [processing, setProcessing] = useState(false);

  const { data, loading } = PackingDetailHook(containerNumber);
  const [shipped, setShipped] = useState(false);
  const [printing, setPrinting] = useState(false);
  const { data: warehouseList } = WarehouseListHook();
  const navigate = useNavigate();

  const resetScan = () => {
    setCurrentScannedUPC('');
  };

  const key = useMemo(() => (data?.tote ? 'tote' : 'pallet'), [data]);

  const warehouseDetail = useMemo(() => {
    if (data?.[key]?.warehouseId && CommonUtility.isValidArray(warehouseList)) {
      const relevantWarehouse = warehouseList.find(
        wh => wh._id === data?.[key]?.warehouseId,
      );

      if (!relevantWarehouse) {
        return null;
      }

      // if there is shipnode but either computer or printer is not found
      if (
        !!relevantWarehouse?.shipnodeConfigurations?.shipnode &&
        (!relevantWarehouse?.shipnodeConfigurations?.printerId ||
          !relevantWarehouse?.shipnodeConfigurations?.computerId)
      ) {
        ToastMessage.error('Shipnode not configured properly');
        return null;
      }
      return relevantWarehouse;
    }
    return null;
  }, [warehouseList, data, key]);

  useBarcodeScanner(code => setCurrentScannedUPC(code));

  const packItem = async code => {
    const findRelevantItem = items?.find(
      x => x?.productId?.upc === code && x.status !== 'packed',
    );

    if (!findRelevantItem) {
      ToastMessage.error('Item not found, try scanning again');
      return;
    }

    setProcessing(true);

    const payload = {
      allocationId:
        findRelevantItem?.allocationId?._id || findRelevantItem?.allocationId,
    };

    try {
      await PackagingService.packToteItem(data?.[key]?._id, payload);
      resetScan();
      setItems(items =>
        items.map(item => {
          if (item._id === findRelevantItem._id) {
            return {
              ...item,
              status: 'packed',
            };
          }
          return item;
        }),
      );
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (CommonUtility.isValidArray(data?.[key]?.contents)) {
      setItems(data?.[key]?.contents);
    }
  }, [data, key]);

  const allPacked = useMemo(
    () => items?.every(item => item.status === 'packed'),
    [items],
  );

  const isPallet = key === 'pallet';

  useEffect(() => {
    if (currentScannedUPC) {
      if (isPallet) {
        fulfillItem(currentScannedUPC);
      } else {
        packItem(currentScannedUPC);
      }
    }
  }, [currentScannedUPC, isPallet]);

  const fulfillItem = async code => {
    const findRelevantItem = items?.find(
      x => x?.productId?.upc === code && x.status !== 'shipped',
    );

    if (!findRelevantItem) {
      ToastMessage.error('Item not found, try scanning again');
      return;
    }

    const payload = {
      palletId: data?.[key]?._id,
      allocations: [
        {
          allocationId: findRelevantItem?.allocationId?._id,
          quantity: 1,
        },
      ],
    };

    try {
      setProcessing(true);
      const res = await PackagingService.shipItem(
        findRelevantItem.order._id,
        payload,
      );
      resetScan();
      setItems(items =>
        items.map(item => {
          if (item?.allocationId?._id === findRelevantItem?.allocationId?._id) {
            return {
              ...item,
              shippingLabel: res.shippedAllocations?.[0]?.shippingLabel,
              status: 'shipped',
            };
          }
          return item;
        }),
      );
      await askToPrint(findRelevantItem?.shipping?.labels?.[0]?.url);

      // check if any other item is pending
      const itemLeft = items?.find(
        x =>
          x?.allocationId?._id !== findRelevantItem?.allocationId?._id &&
          x.status !== 'shipped',
      );

      if (!itemLeft) {
        ToastMessage.success('All items shipped successfully');
        navigate('/app/fulfill/packing/list/complete');
      }
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  const fulfillTote = async () => {
    try {
      setProcessing(true);
      await PackagingService.shipItem(data?.[key]?.order?._id, {
        toteId: data?.[key]?._id,
      });
      setShipped(true);
      ToastMessage.success('Tote shipped successfully');
      await print();
      navigate('/app/fulfill/packing/list/complete');
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  const unPackItem = async item => {
    const findRelevantItem = items?.find(
      x => x?.allocationId === item.allocationId,
    );

    if (!findRelevantItem) {
      ToastMessage.error('Item not found, try scanning again');
      return;
    }

    setProcessing(true);

    const payload = {
      allocationId: findRelevantItem?.allocationId,
      unpack: true,
    };

    try {
      await PackagingService.packToteItem(data?.[key]?._id, payload);
      resetScan();
      setItems(items =>
        items.map(item => {
          if (item._id === findRelevantItem._id) {
            return {
              ...item,
              status: 'ready_to_pack',
            };
          }
          return item;
        }),
      );
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  const askToPrint = async toPrint => {
    if (toPrint) {
      if (warehouseDetail?.shipnodeConfigurations?.shipnode) {
        // do shipnode printing
        try {
          setPrinting(true);
          const body = {
            message: new Date().toString(),
            computer_id: warehouseDetail?.shipnodeConfigurations.computerId,
            printer_id: warehouseDetail?.shipnodeConfigurations.printerId,
            url: toPrint,
          };
          await PackagingService.printLabel(body);
        } catch (error) {
          ToastMessage.apiError(error);
        } finally {
          setPrinting(false);
        }
      } else {
        setPrinting(true);
        await fetch(toPrint)
          .then(response => response.blob())
          .then(blob => {
            const blobURL = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');

            iframe.style.position = 'absolute';
            iframe.style.width = '0px';
            iframe.style.height = '0px';
            iframe.style.border = 'none';

            document.body.appendChild(iframe);
            iframe.src = blobURL;

            iframe.onload = () => {
              setTimeout(() => {
                iframe.contentWindow.focus(); // Ensure the iframe is focused before printing
                iframe.contentWindow.print();
              }, 500); // Small delay to ensure rendering

              // Cleanup after printing
              iframe.contentWindow.onafterprint = () => {
                document.body.removeChild(iframe);
                URL.revokeObjectURL(blobURL);
              };
            };
          })
          .catch(error => console.error('Failed to load print content:', error))
          .finally(() => {
            setPrinting(false);
          });
      }
    }
  };

  const print = async () => {
    if (CommonUtility.isValidObject(data?.[key]?.shipping)) {
      const toPrint = data?.[key]?.shipping?.labels?.[0]?.url;
      await askToPrint(toPrint);
    }
  };

  return (
    <BodyContainer>
      <PackingDetailHeader
        allPacked={allPacked}
        loading={loading}
        isPallet={isPallet}
        data={data?.[key]}
        fulfillTote={fulfillTote}
        shipped={shipped}
        print={print}
        printing={printing}
      />
      <div className="row mt-4">
        <PackingDetailItems
          items={items}
          data={data?.[key]}
          isPallet={isPallet}
          loading={loading || processing}
          packItem={packItem}
          fulfillItem={fulfillItem}
        />
        <PackingDetailPacks
          unPackItem={unPackItem}
          isPallet={isPallet}
          items={items}
          loading={loading || processing}
        />
      </div>
    </BodyContainer>
  );
}
