import { useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { countryListHook, WarehouseShipnodeHook } from '../../../hooks';
import {
  CheckboxFormField,
  ControlledPhoneFormField,
  ControlledTextFormField,
  CountryDropdownFormField,
  DropdownFormField,
  NumberFormField,
  ShadowBox,
} from '../../../elements';
import {
  CommonUtility,
  decimalMask,
  measurementOptions,
  ToastMessage,
} from '../../../utility';
import { LoaderBar } from '../../../components';
import { dropdownOptionsIncludeFlag } from '../../../elements/Flag';
import { AlertFooterBar } from '../../../elements/FooterAlert';
import { WarehouseService } from '../../../utility/services/warehouse';
import { useWarehouseDetails } from '../../../screens/app/settings/warehouse';

const TabContainer = styled.div``;

const WarehouseSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Enter a valid Email'),
  phone: yup.string(),
  addressLineOne: yup.string().required('Address Line 1 is required'),
  addressLineTwo: yup.string(),
  computerId: yup
    .string()
    .when('shipnode', (shipnode, schema) =>
      shipnode
        ? schema.required('Computer is required')
        : schema.optional().nullable(),
    ),
  printerId: yup
    .string()
    .when('shipnode', (shipnode, schema) =>
      shipnode
        ? schema.required('Printer is required')
        : schema.optional().nullable(),
    ),
  city: yup.string().required('City is required'),
  countryCode: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  zipCode: yup.string().required('Zip Code is required'),
  shipnode: yup.boolean(),
  length: yup
    .number()
    .positive('Length must be greater than 0')
    .typeError('Length is required')
    .required('Length is required'),
  width: yup
    .number()
    .positive('Width must be greater than 0')
    .typeError('Width is required')
    .required('Width is required'),
  height: yup
    .number()
    .positive('Height must be greater than 0')
    .typeError('Height is required')
    .required('Height is required'),
  measurementUnit: yup.string().required('Measurement Unit is required'),
});

export function WarehouseForm() {
  const { warehouseData, warehouseDataLoading, refreshWarehouse, warehouseId } =
    useWarehouseDetails();
  const { data: countryData } = countryListHook();
  const [saving, setSaving] = useState(false);
  const { data: shipnodeData, loading: shipnodeLoading } =
    WarehouseShipnodeHook(warehouseId);

  const [computerOptions, setComputerOptions] = useState([]);
  const [printerOptions, setPrinterOptions] = useState([]);

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(WarehouseSchema),
  });

  const isShipNode = watch('shipnode');
  const computerId = watch('computerId');

  useEffect(() => {
    if (CommonUtility.isValidArray(shipnodeData)) {
      const computerOptions = shipnodeData.map(shipnode => ({
        text: shipnode.name,
        value: shipnode.id,
        key: shipnode.id,
        printers: Object.values(shipnode.printers).map(printer => ({
          text: printer.name,
          value: printer.id,
          key: printer.id,
        })),
      }));

      setComputerOptions(computerOptions);
      if (computerOptions?.length === 1) {
        setValue('computerId', computerOptions[0].value);
      }
    }
  }, [shipnodeData]);

  useEffect(() => {
    if (computerId && CommonUtility.isValidArray(computerOptions)) {
      const printerOptions = computerOptions.find(
        computer => computer.value === computerId,
      ).printers;
      setPrinterOptions(printerOptions);
      if (printerOptions?.length === 1) {
        setValue('printerId', printerOptions[0].value);
      }
    } else {
      setPrinterOptions([]);
    }
  }, [computerId, computerOptions]);

  useEffect(() => {
    if (!CommonUtility.isValidObject(warehouseData)) return;
    resetForm(warehouseData);
  }, [warehouseData]);

  const resetForm = data => {
    reset({
      ...data.address,
      ...data.dimensions,
      ...(data?.shipnodeConfigurations || {}),
      name: data?.name,
      email: data?.email,
      phone: data?.phone,
    });
  };

  const onSubmit = async formData => {
    try {
      setSaving(true);

      let payload = {
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        address: {
          addressLineOne: formData.addressLineOne,
          addressLineTwo: formData.addressLineTwo,
          city: formData.city,
          state: formData.state,
          zipCode: formData.zipCode,
          countryCode: formData.countryCode,
        },
        dimensions: {
          length: formData.length,
          width: formData.width,
          height: formData.height,
          measurementUnit: formData.measurementUnit,
        },
        shipnodeConfigurations: {
          shipnode: formData.shipnode,
        },
      };

      if (payload.shipnodeConfigurations.shipnode) {
        payload.shipnodeConfigurations = {
          ...payload.shipnodeConfigurations,
          printerId: formData.printerId,
          computerId: formData.computerId,
        };
      }

      payload = CommonUtility.removeEmptyFields(payload);

      const res = await WarehouseService.updateWarehouse(warehouseId, payload);
      resetForm(res);
      refreshWarehouse();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setSaving(false);
    }
  };

  return (
    <TabContainer>
      {warehouseDataLoading && <LoaderBar />}
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ShadowBox>
          <h3>Warehouse Details</h3>
          <Form.Group widths="equal">
            <ControlledTextFormField
              control={control}
              name="name"
              error={errors.name}
              label="Name"
              hint={errors.name?.message}
              required
              width={4}
            />
            <ControlledTextFormField
              control={control}
              name="email"
              error={errors.email}
              label="E-mail"
              required
              hint={errors.email?.message}
              width={4}
            />
          </Form.Group>
          <Form.Group>
            <ControlledPhoneFormField
              error={errors.phone}
              hint={errors.phone?.message}
              label="Phone"
              width={8}
              name="phone"
              control={control}
              inputContainerClass="input-height"
            />
          </Form.Group>
          <Form.Group widths="equal">
            <NumberFormField
              control={control}
              name="length"
              error={errors.length}
              label="Length"
              required
              hint={errors.length?.message}
              width={2}
              maskOptions={decimalMask}
            />
            <NumberFormField
              control={control}
              name="width"
              error={errors.width}
              label="Width"
              hint={errors.width?.message}
              required
              maskOptions={decimalMask}
              width={2}
            />
            <NumberFormField
              maskOptions={decimalMask}
              control={control}
              name="height"
              error={errors.height}
              label="Height"
              required
              hint={errors.height?.message}
              width={2}
            />
            <DropdownFormField
              control={control}
              label="Measurement Unit"
              error={errors.measurementUnit}
              name="measurementUnit"
              hint={errors.measurementUnit?.message}
              width={4}
              options={measurementOptions}
            />
          </Form.Group>
        </ShadowBox>
        <ShadowBox className="my-3">
          <h3>Address</h3>
          <Form.Group widths="equal">
            <ControlledTextFormField
              control={control}
              label="Address Line 1"
              error={errors.addressLineOne}
              name="addressLineOne"
              hint={errors.addressLineOne?.message}
              required
            />
            <ControlledTextFormField
              control={control}
              label="Address Line 2"
              error={errors.addressLineTwo}
              name="addressLineTwo"
              hint={errors.addressLineTwo?.message}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <ControlledTextFormField
              control={control}
              name="city"
              error={errors.city}
              label="City"
              hint={errors.city?.message}
              required
            />
            <CountryDropdownFormField
              control={control}
              name="countryCode"
              error={errors.countryCode}
              label="Country"
              hint={errors.countryCode?.message}
              required
              options={dropdownOptionsIncludeFlag(countryData)}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <ControlledTextFormField
              control={control}
              name="state"
              error={errors.state}
              label="State"
              hint={errors.state?.message}
              required
            />
            <ControlledTextFormField
              control={control}
              name="zipCode"
              error={errors.zipCode}
              label="Zip Code"
              hint={errors.zipCode?.message}
              required
            />
          </Form.Group>
        </ShadowBox>
        <ShadowBox>
          <h3>Printing</h3>
          <CheckboxFormField
            control={control}
            name="shipnode"
            error={errors.shipnode}
            label="Ship Node"
            hint={errors.shipnode?.message}
          />
          {isShipNode && (
            <>
              <DropdownFormField
                control={control}
                name="computerId"
                error={errors.computerId}
                label="Computer Id"
                hint={errors.computerId?.message}
                options={computerOptions}
                loading={shipnodeLoading}
              />
              <DropdownFormField
                control={control}
                name="printerId"
                error={errors.printerId}
                label="Printer Id"
                hint={errors.printerId?.message}
                options={printerOptions}
                loading={shipnodeLoading}
              />
            </>
          )}
        </ShadowBox>
        {CommonUtility.isValidObject(dirtyFields) && (
          <AlertFooterBar
            message="Save Changes"
            cancel={() => resetForm(warehouseData)}
            save={handleSubmit(onSubmit)}
            loading={saving}
          />
        )}
      </Form>
    </TabContainer>
  );
}
