import { Check, Circle, Cube } from '@phosphor-icons/react';
import { Loader } from 'semantic-ui-react';
import {
  ActionEditDots,
  ActionMenuItem,
  BackButton,
  TitleBackButton,
} from '../../../components';
import {
  ColoredPill,
  HeaderBar,
  PageTitle,
  TitleBarTitle,
  TitleHeaderBar,
} from '../../../elements';
import { theme } from '../../../utility';
import { DesktopMode, MobileMode } from '../../../layouts/ResponsiveMedia';

export function OrderDetailsHeader({ data, openPopup, showAction, loading }) {
  return (
    <>
      <MobileMode>
        <HeaderBar className="row">
          <div className="col-9 row align-items-center">
            <BackButton />
            {loading ? (
              <Loader active inline />
            ) : (
              <>
                <PageTitle className="d-flex align-items-center">
                  <Cube size={25} />
                  <span className="ml-2">
                    {data?.marketplaceOrderDisplayId}
                  </span>
                </PageTitle>
                <ColoredPill color={theme.colors.green} className="ml-4">
                  <Circle weight="fill" color={theme.colors.green} />
                  {data?.states?.[0]}
                </ColoredPill>
                <ColoredPill color={theme.colors.grey} className="ml-4">
                  <Cube />
                  {data?.items?.length} Items
                </ColoredPill>
              </>
            )}
          </div>
          <div className="col-3 row justify-content-end">
            {showAction && (
              <ActionEditDots
                showByDefault
                inline
                basic
                preventChangingBg
                on="hover"
                position="bottom left"
              >
                <ActionMenuItem
                  onClick={() => {
                    openPopup();
                  }}
                >
                  <Check />
                  Fulfill
                </ActionMenuItem>
              </ActionEditDots>
            )}
          </div>
        </HeaderBar>
      </MobileMode>
      <DesktopMode>
        <TitleHeaderBar className="row align-items-top">
          <div className="d-flex col-8">
            <TitleBackButton />
            <TitleBarTitle className="d-flex align-items-center">
              <Cube size={20} className="mr-1 icon" />{' '}
              <span>{data?.marketplaceOrderDisplayId}</span>
            </TitleBarTitle>
            <ColoredPill color={theme.colors.green} className="ml-4">
              <Circle weight="fill" color={theme.colors.green} />
              {data?.states?.[0]}
            </ColoredPill>
            <ColoredPill color={theme.colors.grey} className="ml-4">
              <Cube />
              {data?.items?.length} Items
            </ColoredPill>
          </div>
          <div className="col-4 d-flex justify-content-end">
            {showAction && (
              <ActionEditDots
                showByDefault
                inline
                basic
                preventChangingBg
                on="hover"
                position="bottom right"
                smallBtn
              >
                <ActionMenuItem
                  onClick={() => {
                    openPopup();
                  }}
                >
                  <Check />
                  Fulfill
                </ActionMenuItem>
              </ActionEditDots>
            )}
          </div>
        </TitleHeaderBar>
      </DesktopMode>
    </>
  );
}
