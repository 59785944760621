import { CacheUtility } from '../cache';
import { CommonUtility } from '../common';
import { APIPath } from '../constants';
import { BaseService } from './base';
import { CRUDService } from './crud';

class Packaging extends CRUDService {
  constructor() {
    super(APIPath.ordersPackagingContainers);
  }

  getPackingContainerDetails = async params => {
    const url = `${
      APIPath.ordersPackagingContainerContents
    }/?${CommonUtility.objectToParams(params)}`;

    const cached = CacheUtility.get(url);

    if (cached) {
      return cached;
    }

    const res = await BaseService.get(url);
    CacheUtility.save(url, res, 0.2);

    return res;
  };

  packToteItem(id, body) {
    return BaseService.put(`${APIPath.ordersPackagingTote}/${id}/pack`, body);
  }

  shipItem(id, body) {
    return BaseService.put(`${APIPath.orders}/${id}/allocations/ship`, body);
  }

  getCompletedContainers(params) {
    const url = `${
      APIPath.ordersPackagingContainersCompleted
    }?${CommonUtility.objectToParams(params)}`;
    return BaseService.get(url);
  }

  printLabel(body) {
    return BaseService.post(APIPath.printLabel, body);
  }
}

const PackagingService = new Packaging();
Object.freeze(PackagingService);
export { PackagingService };
