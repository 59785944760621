import { CaretDown } from '@phosphor-icons/react';
import { useEffect } from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { CacheUtility, localStorageKeys } from '../utility';

const StyledDropdown = styled(Dropdown)`
  font-size: 20px;
`;

const TitleDropdown = styled(Dropdown)`
  color: ${({ theme }) => theme.colors.grey} !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  background: ${({ theme }) => theme.colors.primaryLight};
  white-space: nowrap;
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 5px;
`;

const DropdownIcon = styled(CaretDown)`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 0px;
  right: 0.7em;
  z-index: 3;
  right: -24px;
  opacity: 0.8;
  transition: opacity 0.1s ease;
`;

const TitleDropdownIcon = styled(CaretDown)`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 7px;
  right: 5px;
  z-index: 3;
  opacity: 0.8;
  transition: opacity 0.1s ease;
`;

export function WarehouseDropdown({
  warehouseOptions,
  warehouseId,
  warehouseLoading,
  setWarehouseId,
  defaultWarehouseId,
  titleMode = false,
  ...props
}) {
  const cacheKey = localStorageKeys.warehouseId;

  useEffect(() => {
    const cache = CacheUtility.get(cacheKey);
    if (cache?.id) {
      setWarehouseId(cache?.id);
    } else if (defaultWarehouseId) {
      setWarehouseId(defaultWarehouseId);
    }
  }, [defaultWarehouseId]);

  const onChange = id => {
    setWarehouseId(id);
    CacheUtility.save(cacheKey, { id });
  };

  if (titleMode) {
    return (
      <TitleDropdown
        inline
        options={warehouseOptions}
        value={warehouseId}
        loading={warehouseLoading}
        onChange={(e, { value }) => onChange(value)}
        icon={<TitleDropdownIcon size={15} />}
        {...props}
      />
    );
  }

  return (
    <StyledDropdown
      inline
      options={warehouseOptions}
      value={warehouseId}
      loading={warehouseLoading}
      onChange={(e, { value }) => onChange(value)}
      icon={<DropdownIcon size={18} />}
      {...props}
    />
  );
}
