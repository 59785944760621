import { TableCell, TableRow } from 'semantic-ui-react';
import { Stack, Tray } from '@phosphor-icons/react';
import { Link } from 'react-router-dom';
import { ColoredPill, ColorText, MuteText, StyledButton } from '../../elements';
import { PackingStatus } from './common';
import { DateFormat, DateUtility, theme } from '../../utility';

function PackingTime({ time, isEnabled }) {
  let timeColor = '';
  const noOfDays = DateUtility.noOfDays(time);
  if (isEnabled) {
    if (noOfDays < 1) {
      timeColor = theme.colors.green;
    } else if (noOfDays <= 2) {
      timeColor = theme.colors.yellow;
    } else if (noOfDays <= 5) {
      timeColor = theme.colors.orange;
    } else if (noOfDays > 5) {
      timeColor = theme.colors.danger;
    }
  }
  return (
    <ColorText color={timeColor}>
      <b>{DateUtility.hourMinuteTime(time)}</b>
    </ColorText>
  );
}

export function PackagingListCompletedRow({ item }) {
  return (
    <TableRow>
      <TableCell>{item?.wave?.[0]?.waveNumber}</TableCell>
      <TableCell>
        <PackingStatus status={item?.status} />
      </TableCell>
      <TableCell>
        <PackingTime time={item.createdAt} isEnabled />
        <div>
          <MuteText>
            {DateUtility.formatDate(item.createdAt, DateFormat.barChartMonth)}
          </MuteText>
        </div>
      </TableCell>
      <TableCell>
        {item?.palletNumber ? (
          <ColoredPill>
            <Stack />
            {item?.palletNumber}
          </ColoredPill>
        ) : (
          <ColoredPill>
            <Tray />
            {item?.toteNumber}
          </ColoredPill>
        )}
      </TableCell>
      <TableCell>{item?.contents?.length}</TableCell>
      <TableCell>
        <Link to={`/app/fulfill/packing/completed/${item._id}`}>
          <StyledButton>View Details</StyledButton>
        </Link>
      </TableCell>
    </TableRow>
  );
}
