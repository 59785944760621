import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CaretDown } from '@phosphor-icons/react';
import { Dropdown } from 'semantic-ui-react';
import { ZoneDetailHook } from '../../../../hooks/zone';
import {
  CreateAislePopup,
  CreateRackPopup,
  EditAislePopup,
  RackViewer,
  StaticRackViewer,
  ZoneDimensionsDetails,
} from '../../../../page-components/zones';
import { CommonUtility } from '../../../../utility';
import { RandomUtility } from '../../../../utility/random';

const StyledDropdown = styled(Dropdown)`
  color: ${({ theme }) => theme.colors.grey} !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  background: ${({ theme }) => theme.colors.primaryLight};
  white-space: nowrap;
  padding: 5px 10px;
  padding-right: 30px;
  border-radius: 5px;
`;

const DropdownIcon = styled(CaretDown)`
  cursor: pointer;
  position: absolute;
  width: auto;
  height: auto;
  line-height: 1.21428571em;
  top: 7px;
  right: 5px;
  z-index: 3;
  opacity: 0.8;
  transition: opacity 0.1s ease;
`;

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: ${({ zIndex }) => zIndex || '997'};
  transition: all 0.2s;
  height: 40px;
  width: 40vw;
  right: 130px;
  padding: 5px 0;
  display: flex;
  justify-content: end;
  button {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

function Header({ zoneId, zoneOptions, onChangeZone }) {
  return (
    <HeaderContainer>
      <StyledDropdown
        inline
        options={zoneOptions}
        value={zoneId}
        icon={<DropdownIcon size={18} />}
        onChange={(e, { value }) => onChangeZone(value)}
      />
    </HeaderContainer>
  );
}

export function ZoneDetailScreen({
  zoneId,
  zoneOptions,
  onChangeZone,
  data,
  refreshData,
  count,
  aloneRacks,
  loading,
  setData,
  setAloneRacks,
  updatePositions,
}) {
  const [rackPopup, setRackPopup] = useState(false);
  const [aislePopup, setAislePopup] = useState(false);
  const [aisle, setAisle] = useState(null);
  const [hasIsles, setHasIsles] = useState(false);
  const [editAislePopup, setEditAislePopup] = useState(null);

  const { data: ZoneData, refresh: refreshZoneData } = ZoneDetailHook(zoneId);

  useEffect(() => {
    if (CommonUtility.isValidArray(data)) {
      setHasIsles(true);
    }
  }, [data]);

  const toggleAisles = () => {
    setHasIsles(!hasIsles);
  };

  const openAislePopup = () => {
    setAislePopup(true);
  };

  const closeAislePopup = () => {
    setAislePopup(false);
  };

  const closeRackPopup = () => {
    setRackPopup(false);
    setAisle(null);
  };

  const addRackInAsile = aisle => {
    setAisle(aisle);
    setRackPopup(true);
  };

  const onCloseEditAislePopup = () => {
    setEditAislePopup(null);
  };

  const onClickEditAisle = aisle => {
    setEditAislePopup(aisle);
  };

  const color = useMemo(
    () =>
      ZoneData?.color !== 'rgb(255,255,255)'
        ? ZoneData?.color
        : RandomUtility.generateDarkColor(),
    [ZoneData],
  );

  const showDND = !((count?.racks || 0) > 500);

  return (
    <>
      <Header
        zoneId={zoneId}
        zoneOptions={zoneOptions}
        onChangeZone={onChangeZone}
      />
      <div className="row">
        <div className="col-8 pl-0">
          {showDND ? (
            <RackViewer
              aloneRacks={aloneRacks}
              data={data}
              addRackInAsile={addRackInAsile}
              aisleView={hasIsles}
              loading={loading}
              onClickEditAisle={onClickEditAisle}
              openAislePopup={openAislePopup}
              setData={setData}
              setAloneRacks={setAloneRacks}
              color={color}
              updatePositions={updatePositions}
            />
          ) : (
            <StaticRackViewer
              aloneRacks={aloneRacks}
              data={data}
              addRackInAsile={addRackInAsile}
              aisleView={hasIsles}
              loading={loading}
              onClickEditAisle={onClickEditAisle}
              openAislePopup={openAislePopup}
              setData={setData}
              setAloneRacks={setAloneRacks}
              color={color}
              updatePositions={updatePositions}
            />
          )}
        </div>
        <div className="col-4 p-0 mt-2">
          <ZoneDimensionsDetails
            refresh={refreshZoneData}
            detailLoading={loading}
            data={ZoneData}
            hasAisles={hasIsles}
            toggleAisles={toggleAisles}
            count={count}
            color={color}
          />
        </div>
      </div>
      <CreateRackPopup
        zoneId={zoneId}
        open={rackPopup}
        onClose={closeRackPopup}
        refreshData={refreshData}
        aisle={aisle}
      />
      <CreateAislePopup
        zoneId={zoneId}
        open={aislePopup}
        onClose={closeAislePopup}
        refreshData={refreshData}
      />
      <EditAislePopup
        zoneId={zoneId}
        refreshData={refreshData}
        open={editAislePopup}
        onClose={onCloseEditAislePopup}
        aisle={editAislePopup}
      />
    </>
  );
}
