import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Plus, RectangleDashed } from '@phosphor-icons/react';
import { useMemo, useState } from 'react';
import { MuteText, ShadowBox } from '../../../elements';
import { ZonePopup } from '../zones/ZonePopup';
import { LoaderBar } from '../../../components';
import { RandomUtility } from '../../../utility/random';
import { useWarehouseDetails } from '../../../screens/app/settings/warehouse';

const ZonesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
  .zone {
    width: calc(50% - 5px);
  }
`;

export function WarehoueZones({ zones, zoneLoading, zoneRefresh }) {
  const [zonePopup, setZonePopup] = useState(false);
  const { warehouseData, warehouseDataLoading, warehouseId } =
    useWarehouseDetails();

  const openPopup = () => {
    setZonePopup(true);
  };

  const closePopup = () => {
    setZonePopup(false);
  };

  return (
    <div className="relative">
      {zoneLoading && <LoaderBar />}
      <ZonesContainer>
        {zones?.map(zone => (
          <Zone key={zone._id} data={zone} />
        ))}
        <AddZoneCard onClick={openPopup} className="zone">
          <Plus />
          <span>Create a New Zone</span>
        </AddZoneCard>
      </ZonesContainer>
      <ZonePopup
        warehouseId={warehouseId}
        warehouseData={warehouseData}
        warehouseDataLoading={warehouseDataLoading}
        open={zonePopup}
        onClose={closePopup}
        refresh={zoneRefresh}
      />
    </div>
  );
}

const TypeName = styled.span`
  text-transform: capitalize;
`;

const AddZoneCard = styled.div`
  border: 2px dashed ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.5;
  min-height: 85px;
`;

const ZoneCard = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    .title {
      font-weight: bold;
    }
  }

  .adapt-color {
    color: ${({ color }) => color};
  }
`;

const Rectangle = styled(RectangleDashed)`
  margin-top: -2px;
`;

function Zone({ data }) {
  const color = useMemo(
    () =>
      data.color !== 'rgb(255,255,255)'
        ? data.color
        : RandomUtility.generateDarkColor(),
    [data],
  );

  return (
    <ShadowBox className="zone">
      <ZoneCard color={color} to={`zones/${data._id}`}>
        <div className="d-flex align-items-center mb-3 title">
          <Rectangle size={15} className="mr-2 adapt-color" />
          <MuteText>{data.code}:</MuteText>
          <TypeName className="ml-2 adapt-color">{data.type}</TypeName>
        </div>
        <div className="d-flex justify-content-between">
          <div>{data.aisleCount} Aisles</div>
          <div className="mx-3">{data.rackCount} Racks</div>
          <div>
            <span>{data.dimensions?.length}</span>
            <MuteText className="mx-1">x</MuteText>
            <span>{data.dimensions?.width}</span>
            <MuteText className="mx-1">x</MuteText>
            <span>{data.dimensions?.height}</span>
            <MuteText className="mx-1">
              ({data.dimensions?.measurementUnit})
            </MuteText>
          </div>
        </div>
      </ZoneCard>
    </ShadowBox>
  );
}
