import { AppTable } from '../../../../components';
import { PackagingCompletedHook } from '../../../../hooks/packing';
import { PackagingListCompletedRow } from '../../../../page-components/packaging/list-complete';

export function CompletePickListTab({ warehouseId }) {
  const {
    filter: tableFilter,
    filterChanged,
    loading: orderLoading,
    data: orderData,
    totalData,
    totalPages,
    pageChanged,
  } = PackagingCompletedHook(warehouseId);

  const orderColumns = [
    {
      text: 'Wave ID',
      key: 'wave_id',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Status',
      key: 'status',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Created',
      key: 'created',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Container',
      key: 'container',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Contents',
      key: 'contents',
      width: 2,
      textAlign: 'left',
    },
    {
      text: '',
      key: 'action',
      width: 2,
      textAlign: 'left',
    },
  ];

  return (
    <div>
      <AppTable
        columns={orderColumns}
        loading={orderLoading}
        RowComponent={<PackagingListCompletedRow />}
        headerOffSet={32}
        data={orderData}
        totalData={totalData}
        totalPages={totalPages}
        pageChanged={pageChanged}
        currentPage={tableFilter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={tableFilter.limit}
      />
    </div>
  );
}
