import { TableCell, TableRow } from 'semantic-ui-react';
import { Check, Circle, Printer } from '@phosphor-icons/react';
import { useState } from 'react';
import styled from 'styled-components';
import {
  BorderTableCell,
  BorderTableRow,
  ItemImage,
} from '../../../components';
import {
  BoldText,
  HoverBorderButton,
  MuteText,
  OrangePill,
  StyledButton,
} from '../../../elements';
import { CommonUtility } from '../../../utility';
import { ExpandCollapseToggle } from '../../shipments/Items/commonComponents';

const ButtonSizedDiv = styled.div`
  width: 160px;
`;

const OrangeStatus = styled(OrangePill)`
  height: 24px;
  padding: 0 10px;
`;

export function SortedWaveRow({ item, fulfillOrder }) {
  const [open, setOpen] = useState(false);

  const product = item.inventory.product;
  const labels = item.shipping.labels;

  const isMultiple = labels?.length > 1;

  return (
    <>
      <TableRow>
        <TableCell>
          <div className="d-flex">
            <ItemImage
              item={product}
              displayImageKey="display_image"
              imageKey="images"
            />
            <div className="d-flex flex-column ml-4">
              <span>{product.name}</span>
              <div className="d-flex mt-2">
                <MuteText>SKU</MuteText>
                <BoldText className="ml-2">{product.sku}</BoldText>
              </div>
            </div>
          </div>
        </TableCell>
        <TableCell textAlign="center">{item.quantity}</TableCell>
        <TableCell textAlign="center">
          <OrangeStatus>
            <Circle weight="fill" />
            {CommonUtility.toTitleCase(item.status)}
          </OrangeStatus>
        </TableCell>
        <TableCell>
          <div className="d-flex justify-content-center flex-wrap">
            {isMultiple ? (
              <div className="mr-4 ml-2">
                <ExpandCollapseToggle
                  title="Multiple Labels"
                  open={open}
                  setOpen={setOpen}
                  className="mr-4"
                />
              </div>
            ) : (
              labels?.[0]?.url && (
                <a
                  download
                  href={labels?.[0]?.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  <HoverBorderButton className="flex-btn text-nowrap">
                    <Printer className="mr-2 mb-1" size={17} /> Print Label
                  </HoverBorderButton>
                </a>
              )
            )}
            <StyledButton
              onClick={() => fulfillOrder(item)}
              className="ml-2 flex-btn text-nowrap"
            >
              <Check className="mr-2 mb-1" size={17} /> Mark Fulfilled
            </StyledButton>
          </div>
        </TableCell>
      </TableRow>
      {isMultiple && open && <MultipleLabelsContainer labels={labels} />}
    </>
  );
}

const MultipleLabelsContainer = ({ labels }) =>
  labels?.map(label => (
    <BorderTableRow key={label.tracking}>
      <BorderTableCell expand="true" textAlign="right">
        <MuteText className="mr-1">Tracking No.</MuteText> {label.tracking}
        <div className="border" />
      </BorderTableCell>
      <TableCell textAlign="center">
        <MuteText className="mr-1">Carrier</MuteText>{' '}
        {CommonUtility.toTitleCase(label.carrier)}
      </TableCell>
      <TableCell textAlign="center">
        <MuteText className="mr-1">Service</MuteText> {label.service?.key}
      </TableCell>
      <TableCell>
        <div className="d-flex justify-content-center">
          <a download href={label?.url} target="_blank" rel="noreferrer">
            <HoverBorderButton className="flex-btn text-nowrap">
              <Printer className="mr-2 mb-1" size={17} /> Print Label
            </HoverBorderButton>
          </a>
          <ButtonSizedDiv />
        </div>
      </TableCell>
    </BorderTableRow>
  ));
