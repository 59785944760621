import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormGroup } from 'semantic-ui-react';
import {
  cloneElement,
  isValidElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Clock, Plus, X } from '@phosphor-icons/react';
import styled from 'styled-components';
import {
  CommonUtility,
  DateUtility,
  ToastMessage,
  WaveService,
} from '../../utility';
import { SkupremeModal } from '../../components';
import {
  CenterDropdownStyle,
  CheckboxFormField,
  ControlledTextFormField,
  DateFormField,
  DropdownFormField,
  HoverBorderButton,
  MuteBorder,
  MuteText,
  NumberFormField,
  StyledButton,
} from '../../elements';
import { WarehouseZonesHook } from '../../hooks/zone';

const StyledDropdown = styled(DropdownFormField)`
  &.dropdown {
    min-width: 200px !important;
  }
`;

const FieldRow = styled.div`
  column-gap: 10px;
  row-gap: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  .mute-dropdown {
    margin-top: 22px;
    width: 200px !important;
  }
`;

const fieldsRenderer = {
  array: <StyledDropdown sorted={false} />,
  number: <NumberFormField />,
  string: <ControlledTextFormField />,
  time: (
    <DateFormField
      icon={<Clock size={26} className="icon" />}
      showTimeSelect
      showTimeSelectOnly
      timeCaption="Time"
      timeIntervals={15}
    />
  ),
};

const fieldMap = {
  array: 'array',
  number: 'number',
  string: 'string',
  time: 'string',
};

const StyledForm = styled(Form)`
  .mute-dropdown {
    width: 260px;
  }
`;

const CrossIcon = styled(X)`
  cursor: pointer;
  min-width: 28px;
  color: ${({ theme }) => theme.colors.grey};
  margin-top: 8px;
  margin-left: auto;
`;

const generateSchema = (
  rulesMetadata,
  activeCriteriaFields,
  activeReleaseFields,
) =>
  yup.object().shape({
    ...(() => {
      const yupObject = {
        name: yup.string().required('Rule name is Required'),
        isActive: yup.boolean(),
        userGroupRole: yup.string().optional(),
      };
      Object.keys(rulesMetadata?.criteria || {}).forEach(keyName => {
        const customField = rulesMetadata?.criteria[keyName];

        const fieldType = fieldMap[customField.type];
        let yupField = yup[fieldType]();
        if (activeCriteriaFields.includes(keyName)) {
          yupField = yupField.required('Field is Required');
        }
        yupObject[keyName] = yupField;
        if (customField.conditions) {
          yupObject[`${keyName}-condition`] = yup.string();
          if (activeCriteriaFields.includes(keyName)) {
            yupObject[`${keyName}-condition`] = yupObject[
              `${keyName}-condition`
            ].required('Condition is Required');
          }
        }
      });

      Object.keys(rulesMetadata?.releaseTypes || {}).forEach(keyName => {
        const customField = rulesMetadata?.releaseTypes[keyName];

        if (customField.type === 'object') {
          const fieldObject = yup.object().shape({
            ...(() => {
              const yupObject = {};
              Object.keys(customField.fields).forEach(fieldKey => {
                const field = customField.fields[fieldKey];
                let fieldType = fieldMap[field.type];
                if (field.type === 'array' && !field.multiple) {
                  fieldType = fieldMap.string;
                }

                let yupField = yup[fieldType]();
                if (activeReleaseFields.includes(keyName)) {
                  yupField = yupField.required('Field is Required');
                }
                yupObject[fieldKey] = yupField;
              });
              return yupObject;
            })(),
          });
          yupObject[keyName] = fieldObject;
        } else {
          let fieldType = fieldMap[customField.type];
          if (customField.type === 'array' && !customField.multiple) {
            fieldType = fieldMap.string;
          }
          let yupField = yup[fieldType]();
          if (activeReleaseFields.includes(keyName)) {
            yupField = yupField.required('Field is Required');
          }
          yupObject[keyName] = yupField;
        }
      });
      return yupObject;
    })(),
  });

function extractValue(obj, path) {
  const keyParts = path.split('.');
  let value = obj;

  for (const part of keyParts) {
    if (value && value.hasOwnProperty(part)) {
      value = value[part];
    } else {
      value = undefined;
      break;
    }
  }

  return value;
}

function isTime(timeString) {
  const timeFormatRegex = /^([01]?\d|2[0-3]):([0-5]\d)$/;

  return timeFormatRegex.test(timeString);
}

function convertToTimeDateObject(timeString) {
  // Extract hours and minutes
  const [hours, minutes] = timeString.split(':').map(Number);

  // Create a Date object with today's date and the given time
  const now = new Date();
  const dateObject = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hours,
    minutes,
  );

  return dateObject;
}

function convertTimeToDate(obj) {
  const process = value => {
    if (isTime(value)) {
      return convertToTimeDateObject(value);
    }

    if (Array.isArray(value)) {
      return value.map(process);
    }

    if (value && typeof value === 'object') {
      Object.keys(value).forEach(key => {
        value[key] = process(value[key]);
      });
    }
    return value;
  };
  return process(obj);
}

export function AddWaveRulePopup({
  refresh,
  open,
  onClose,
  item,
  warehouseId,
  rulesMetadata,
}) {
  const [loading, setLoading] = useState(false);
  const [criteriaOptions, setCriteriaOptions] = useState([]);
  const [releaseOptions, setReleaseOptions] = useState([]);

  const [criteriaFields, setCriteriaFields] = useState(['']);
  const [releaseFields, setReleaseFields] = useState(['']);
  const [options, setOptions] = useState({});

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(
      generateSchema(rulesMetadata, criteriaFields, releaseFields),
    ),
    shouldUnregister: true,
  });

  const { data: zones } = WarehouseZonesHook(warehouseId);

  useEffect(() => {
    if (!CommonUtility.isValidObject(rulesMetadata)) return;
    const options = {};
    Object.keys(rulesMetadata.criteria).forEach(criteriaKey => {
      if (rulesMetadata.criteria[criteriaKey].allowAdditions) {
        options[criteriaKey] = [];
      }
    });
    setOptions(options);
  }, [rulesMetadata]);

  useEffect(() => {
    if (CommonUtility.isValidObject(item)) {
      resetUsingItem();
    } else {
      reset({
        name: '',
      });
    }
  }, [item]);

  const resetUsingItem = () => {
    let criteriaFields = [];
    let releaseField = [];
    const options = {};
    const convertedItem = convertTimeToDate(item);

    const resetObj = {
      name: item.name,
      userGroupRole: item.userGroupRole,
      isActive: item.isActive,
      warehouseId,
      zoneRestrictions: item.zoneRestrictions?.map(zone => zone._id) || [],
      productTags: item.productTags || [],
    };

    if (item.zoneRestrictions?.length > 0) {
      criteriaFields.push('zoneRestrictions');
    }

    if (item.productTags?.length > 0) {
      criteriaFields.push('productTags');
    }

    Object.keys(rulesMetadata).forEach(categoryKey => {
      const category = rulesMetadata[categoryKey];
      Object.keys(category).forEach(tagKey => {
        if (category[tagKey].allowAdditions && !category[tagKey].value) {
          const itemOptions = convertedItem[tagKey];
          if (CommonUtility.isValidArray(itemOptions)) {
            options[tagKey] = itemOptions.map(opt => ({
              text: opt,
              value: opt,
              key: opt,
            }));
          }
        }

        if (category[tagKey].value) {
          const value = extractValue(convertedItem, category[tagKey].value);
          resetObj[tagKey] = value;
          if (value && categoryKey === 'releaseTypes') {
            releaseField.push(tagKey);
          }
        }
      });
    });

    if (criteriaFields.length < 1) {
      criteriaFields = [''];
    }

    if (releaseField.length < 1) {
      releaseField = [''];
    }

    setCriteriaFields(criteriaFields);
    setOptions(options);
    setReleaseFields(releaseField);

    setTimeout(() => {
      reset(resetObj);
    }, [100]);
  };

  const zoneOptions = useMemo(() => {
    if (CommonUtility.isValidArray(zones)) {
      return zones?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [zones]);

  const onSubmit = async formData => {
    try {
      setLoading(true);
      let payload = { ...formData, warehouseId };
      payload = CommonUtility.removeEmptyFields(payload);
      payload = DateUtility.convertDatesToISOString(payload);

      if (!payload.zoneRestrictions) {
        payload.zoneRestrictions = [];
      }

      if (!payload.productTags) {
        payload.productTags = [];
      }
      if (!payload.isActive) {
        payload.isActive = false;
      }

      if (CommonUtility.isValidObject(item)) {
        await WaveService.updateRule(item._id, payload);
      } else {
        await WaveService.addRule(payload);
      }
      ToastMessage.success('Zone successfully created.');
      onClose();
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setLoading(false);
    }
  };

  const onCriteriaChange = (value, index) => {
    setCriteriaFields(prevState => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };

  const onReleaseChange = (value, index) => {
    setReleaseFields(prevState => {
      const newState = [...prevState];
      newState[index] = value;
      return newState;
    });
  };

  const onAddCriteria = () => {
    setCriteriaFields(prevState => [...prevState, '']);
  };

  const onAddRelease = () => {
    setReleaseFields(prevState => [...prevState, '']);
  };

  const AddItemToOptions = (name, { value }) => {
    if (!options?.[name]?.find(x => x.value === value)) {
      setOptions(options => ({
        ...options,
        [name]: [
          ...(options[name] || []),
          {
            text: value,
            value,
            key: value,
          },
        ],
      }));
    }
  };

  useEffect(() => {
    if (!CommonUtility.isValidObject(rulesMetadata)) return;

    let releaseOptions = Object.keys(
      JSON.parse(JSON.stringify(rulesMetadata.releaseTypes)),
    ).map(releaseTypeKey => ({
      text: CommonUtility.toTitleCase(releaseTypeKey),
      key: releaseTypeKey,
      value: releaseTypeKey,
    }));

    setReleaseOptions(releaseOptions);
  }, [rulesMetadata]);

  useEffect(() => {
    if (!CommonUtility.isValidObject(rulesMetadata)) return;
    let criteriaOptions = Object.keys(
      JSON.parse(JSON.stringify(rulesMetadata.criteria)),
    ).map(criteriaKey => ({
      text: CommonUtility.toTitleCase(criteriaKey),
      key: criteriaKey,
      value: criteriaKey,
    }));

    criteriaOptions = criteriaOptions.filter(
      x => !criteriaFields.includes(x.value),
    );
    setCriteriaOptions(criteriaOptions);
  }, [criteriaFields, rulesMetadata]);

  const removeCriteria = index => {
    setCriteriaFields(prevState => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  };

  const removeRelease = index => {
    setReleaseFields(prevState => {
      const newState = [...prevState];
      newState.splice(index, 1);
      return newState;
    });
  };

  useEffect(() => {
    if (!CommonUtility.isValidObject(rulesMetadata)) return;
    let releaseOptions = Object.keys(
      JSON.parse(JSON.stringify(rulesMetadata.releaseTypes)),
    ).map(releaseKey => ({
      text: CommonUtility.toTitleCase(releaseKey),
      key: releaseKey,
      value: releaseKey,
    }));

    releaseOptions = releaseOptions.filter(
      x => !releaseFields.includes(x.value),
    );
    setReleaseOptions(releaseOptions);
  }, [releaseFields, rulesMetadata]);

  const updateView = CommonUtility.isValidObject(item);

  if (!CommonUtility.isValidObject(rulesMetadata) || !rulesMetadata) return;

  return (
    <SkupremeModal
      open={open}
      onClose={onClose}
      title={updateView ? 'Edit Wave Rule' : 'New Wave Rule'}
    >
      <StyledForm
        className="position-relative"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="d-flex align-items-center">
          <ControlledTextFormField
            control={control}
            name="name"
            label="Rule Name"
            error={errors.name}
            hint={errors.name?.message}
            required
            fieldClass="col-9 p-0"
          />
          <div className="d-flex ml-4 mt-4">
            <MuteText className="mr-2">Active</MuteText>
            <CheckboxFormField
              control={control}
              name="isActive"
              error={errors.isActive}
              hint={errors.isActive?.message}
            />
          </div>
        </div>
        <MuteBorder className="w-100 mb-3" />
        <div className="pb-4">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0">If orders match the following criteria</h4>
            <HoverBorderButton
              disabled={
                criteriaFields.length >=
                Object.keys(rulesMetadata?.criteria || {}).length
              }
              className="flex-btn tiny"
              size="tiny"
              type="button"
              onClick={onAddCriteria}
            >
              <Plus className="mr-1" /> Criteria
            </HoverBorderButton>
          </div>
          {criteriaFields.map((criteriaKey, index) => {
            const customField = rulesMetadata.criteria[criteriaKey];
            const conditionKey = `${criteriaKey}-condition`;

            let optionsIncludingSelf = JSON.parse(
              JSON.stringify(criteriaOptions),
            );
            if (
              criteriaKey &&
              !optionsIncludingSelf.find(x => x.value === criteriaKey)
            ) {
              optionsIncludingSelf.push({
                text: CommonUtility.toTitleCase(criteriaKey),
                key: criteriaKey,
                value: criteriaKey,
              });
            }

            const singleCondition = customField?.conditions?.length <= 1;

            return (
              <FormGroup
                widths="equal"
                className="mt-2 mb-0 mx-0 align-items-start"
                key={criteriaKey}
              >
                <CenterDropdownStyle
                  placeholder="Select Criteria"
                  selection
                  options={optionsIncludingSelf}
                  value={criteriaKey}
                  onChange={(e, { value }) => {
                    onCriteriaChange(value, index);
                  }}
                  className="mute-dropdown"
                />
                {CommonUtility.isValidArray(customField?.conditions) && (
                  <DropdownFormField
                    control={control}
                    name={conditionKey}
                    fieldClass="mb-0"
                    error={errors[conditionKey]}
                    hint={errors[conditionKey]?.message}
                    required
                    placeholder="Select Condition"
                    options={customField.conditions.map(condition => ({
                      text: CommonUtility.toTitleCase(condition),
                      key: condition,
                      value: condition,
                    }))}
                    defaultValue={
                      singleCondition ? customField?.conditions?.[0] : ''
                    }
                    disabled={!!singleCondition}
                  />
                )}
                {isValidElement(fieldsRenderer[customField?.type]) &&
                  cloneElement(fieldsRenderer[customField?.type], {
                    control,
                    name: criteriaKey,
                    error: errors[criteriaKey],
                    hint: errors[criteriaKey]?.message,
                    required: !!customField.required,
                    fieldClass: 'mb-0',
                    placeholder:
                      customField.placeholder ||
                      CommonUtility.toTitleCase(criteriaKey),
                    multiple: customField.multiple,
                    allowAdditions: customField.allowAdditions,
                    onAddItem: (e, data) => AddItemToOptions(criteriaKey, data),
                    options:
                      (customField.reference === 'wms_zones'
                        ? zoneOptions
                        : customField.allowAdditions
                        ? options?.[criteriaKey]
                        : customField.options) || [],
                  })}
                <CrossIcon onClick={() => removeCriteria(index)} size={20} />
              </FormGroup>
            );
          })}
        </div>
        <MuteBorder className="w-100 mb-3" />
        <div className="my-4 pb-1">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="mb-0">Release Wave</h4>
            <HoverBorderButton
              disabled={
                releaseFields.length >=
                Object.keys(rulesMetadata?.releaseTypes || {}).length
              }
              className="flex-btn tiny"
              size="tiny"
              type="button"
              onClick={onAddRelease}
            >
              <Plus className="mr-1" /> Release
            </HoverBorderButton>
          </div>
          {releaseFields.map((criteriaKey, index) => {
            const customField = rulesMetadata.releaseTypes[criteriaKey];

            let optionsIncludingSelf = JSON.parse(
              JSON.stringify(releaseOptions),
            );
            if (
              criteriaKey &&
              !optionsIncludingSelf.find(x => x.value === criteriaKey)
            ) {
              optionsIncludingSelf.push({
                text: CommonUtility.toTitleCase(criteriaKey),
                key: criteriaKey,
                value: criteriaKey,
              });
            }

            if (CommonUtility.isValidObject(customField?.fields)) {
              return (
                <div
                  key={criteriaKey}
                  className="mt-2 d-flex align-items-center"
                >
                  <FieldRow>
                    <CenterDropdownStyle
                      placeholder="Select Release"
                      selection
                      options={optionsIncludingSelf}
                      value={criteriaKey}
                      onChange={(e, { value }) => {
                        onReleaseChange(value, index);
                      }}
                      className="mute-dropdown"
                    />
                    {Object.keys(customField.fields).map(fieldKey => {
                      const field = customField.fields[fieldKey];
                      const controlledFieldKey = `${criteriaKey}.${fieldKey}`;

                      return isValidElement(fieldsRenderer[field.type]) ? (
                        cloneElement(fieldsRenderer[field.type], {
                          control,
                          name: controlledFieldKey,
                          error: errors[criteriaKey]?.[fieldKey],
                          hint: errors[criteriaKey]?.[fieldKey]?.message,
                          required: !!customField.required,
                          fieldClass: 'mb-0',
                          dateFormat: field.format,
                          multiple: field.multiple,
                          label: CommonUtility.toTitleCase(fieldKey),
                          placeholder: customField.placeholder,
                          options: field.items || [],
                          width: 4,
                        })
                      ) : (
                        <></>
                      );
                    })}
                  </FieldRow>
                  <CrossIcon
                    className="mt-4"
                    onClick={() => removeRelease(index)}
                    size={20}
                  />
                </div>
              );
            }

            return (
              <FormGroup
                widths="equal"
                key={criteriaKey}
                className="mt-2 mb-0 mx-0 align-items-start"
              >
                <CenterDropdownStyle
                  placeholder="Select Release"
                  selection
                  options={optionsIncludingSelf}
                  value={criteriaKey}
                  onChange={(e, { value }) => {
                    onReleaseChange(value, index);
                  }}
                  className="mute-dropdown"
                />
                {isValidElement(fieldsRenderer[customField?.type]) &&
                  cloneElement(fieldsRenderer[customField?.type], {
                    control,
                    name: criteriaKey,
                    error: errors[criteriaKey],
                    hint: errors[criteriaKey]?.message,
                    required: !!customField.required,
                    fieldClass: 'mb-0',
                    placeholder:
                      customField.placeholder ||
                      CommonUtility.toTitleCase(criteriaKey),
                    multiple: customField.multiple,
                    options:
                      customField.reference === 'wms_zones'
                        ? zoneOptions
                        : customField.options || [],
                  })}
                <CrossIcon onClick={() => removeRelease(index)} size={20} />
              </FormGroup>
            );
          })}
        </div>
        <div className="my-4 pb-1">
          <h4 className="mb-0 mb-1">And assign to</h4>
          <ControlledTextFormField
            control={control}
            name="userGroupRole"
            placeholder="User Group"
            error={errors.userGroupRole}
            hint={errors.userGroupRole?.message}
          />
        </div>
        <MuteBorder className="w-100 mb-4" />
        <div className="d-flex justify-content-end">
          <HoverBorderButton type="button" onClick={onClose}>
            Cancel
          </HoverBorderButton>
          <StyledButton type="submit" loading={loading} className="ml-1">
            {updateView ? 'Update' : 'Create'}
          </StyledButton>
        </div>
      </StyledForm>
    </SkupremeModal>
  );
}
