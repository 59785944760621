import { useEffect, useState } from 'react';
import { PackagingService } from '../utility/services/packaging';
import { useDebouncedEffect } from './debounce';

export const PackagingHook = warehouseId => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: 50,
    page: 1,
    search: '',
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const temp = {
        ...filter,
        warehouseId,
        search: (filter.search || '').trim(),
      };
      const res = await PackagingService.get(temp);
      setData(res?.data);
      setTotalData(res?.totalCount);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (warehouseId) {
        fetchData();
      }
    },
    [filter, refreshKey, warehouseId],
    500,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const setSearch = search => {
    filterChanged('search', search);
  };

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  const updateAllFilter = obj => {
    setFilter(filter => ({
      ...filter,
      page: 1,
      ...obj,
    }));
  };

  return {
    data,
    error,
    filter,
    filterChanged,
    loading,
    pageChanged,
    setSearch,
    totalData,
    totalPages,
    refresh,
    updateAllFilter,
  };
};

export const PackagingCompletedHook = warehouseId => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: 50,
    page: 1,
    search: '',
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const temp = {
        ...filter,
        warehouseId,
        search: (filter.search || '').trim(),
      };
      const res = await PackagingService.getCompletedContainers(temp);
      setData(res?.data);
      setTotalData(res?.totalCount);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (warehouseId) {
        fetchData();
      }
    },
    [filter, refreshKey, warehouseId],
    500,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const setSearch = search => {
    filterChanged('search', search);
  };

  const refresh = () => {
    setRefreshKey(Math.random());
  };

  const updateAllFilter = obj => {
    setFilter(filter => ({
      ...filter,
      page: 1,
      ...obj,
    }));
  };

  return {
    data,
    error,
    filter,
    filterChanged,
    loading,
    pageChanged,
    setSearch,
    totalData,
    totalPages,
    refresh,
    updateAllFilter,
  };
};

export const PackingDetailHook = (
  code,
  containerKey = 'code',
  status = null,
) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        [containerKey]: code,
      };

      if (status) {
        params.status = status;
      }
      const res = await PackagingService.getPackingContainerDetails(params);
      setData(res || {});
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (code) {
      fetchData();
    }
  }, [code, status]);

  return { data, loading, error, refresh: fetchData };
};
