import { APIPath } from '../constants';
import { CRUDService } from './crud';

class Pallet extends CRUDService {
  constructor() {
    super(APIPath.pallets);
  }
}

const PalletService = new Pallet();
Object.freeze(PalletService);
export { PalletService };
