import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import styled from 'styled-components';
import { TableCell, TableRow } from 'semantic-ui-react';
import { Eye } from '@phosphor-icons/react';
import {
  BodyContainer,
  ColoredPill,
  MuteText,
  PageTitle,
} from '../../../../elements';
import { PackingDetailHook } from '../../../../hooks/packing';
import { PackingDetailHeader } from '../../../../page-components/packaging';
import { AppTable, ItemImage } from '../../../../components';
import { CommonUtility } from '../../../../utility';

const TitleContainer = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;

export function PickingCompletedDetailScreen() {
  const { containerNumber } = useParams();

  const { data, loading } = PackingDetailHook(
    containerNumber,
    'containerId',
    'completed',
  );
  const key = useMemo(() => (data?.tote ? 'tote' : 'pallet'), [data]);
  const isPallet = key === 'pallet';

  const columns = useMemo(() => {
    let arr = [
      {
        text: 'Item',
        key: 'item',
        textAlign: 'left',
        width: 1,
      },
      {
        text: 'Product Description',
        key: 'details',
        textAlign: 'left',
        width: 2,
      },
      {
        text: 'Quantity',
        key: 'quantity',
        textAlign: 'left',
        width: 1,
      },
      {
        text: isPallet ? 'Label' : 'Status',
        key: 'label',
        textAlign: 'left',
        width: 2,
      },
    ];
    if (isPallet) {
      arr.splice(3, 0, {
        text: 'Order',
        key: 'order',
        textAlign: 'left',
        width: 2,
      });
    }
    return arr;
  }, [isPallet]);

  return (
    <BodyContainer>
      <PackingDetailHeader
        loading={loading}
        isPallet={isPallet}
        data={data?.[key]}
        showActions={false}
      />
      <div className="mt-2">
        <PageTitle>Items</PageTitle>
      </div>
      <AppTable
        data={data?.[key]?.contents}
        columns={columns}
        RowComponent={<ItemRow isPallet={isPallet} />}
      />
    </BodyContainer>
  );
}

const FileHref = styled.a`
  display: flex;
  align-items: center;
`;

function ItemRow({ item, isPallet }) {
  return (
    <TableRow>
      <TableCell>
        <ItemImage
          imageKey="p_images"
          displayImageKey="display_image"
          item={item?.productId}
          size="mini"
        />
      </TableCell>
      <TableCell>
        <div className="d-flex flex-column ml-2">
          <TitleContainer>{item?.productId?.p_title}</TitleContainer>
          <div className="d-flex">
            <MuteText>SKU</MuteText>
            <span className="ml-2">{item?.productId?.p_sku}</span>
          </div>
          <div className="d-flex">
            <MuteText>UPC</MuteText>
            <span className="ml-2">{item?.productId?.upc}</span>
          </div>
        </div>
      </TableCell>
      <TableCell>{item.quantity}</TableCell>
      {isPallet && (
        <TableCell>{item.order?.marketplace_order_display_id}</TableCell>
      )}
      <TableCell>
        {isPallet ? (
          <FileHref
            href={item.shipping?.labels?.[0]?.url}
            download
            target="_blank"
          >
            <Eye className="mr-2" size={18} />
            <span>View Label</span>
          </FileHref>
        ) : (
          <ColoredPill>{CommonUtility.toTitleCase(item.status)}</ColoredPill>
        )}
      </TableCell>
    </TableRow>
  );
}
