import { useState } from 'react';
import { AppTable, LoaderBar } from '../../../../../../components';
import { SortedWaveListHook } from '../../../../../../hooks';
import { SortedWaveRow } from '../../../../../../page-components';
import { OrderService, ToastMessage } from '../../../../../../utility';

export function SingleTab({ warehouseId }) {
  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
    refresh,
  } = SortedWaveListHook(warehouseId);

  const [processing, setProcessing] = useState(false);

  const columns = [
    {
      text: 'Item',
      key: 'item',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Quantity',
      key: 'quantity',
      width: 2,
    },
    {
      text: 'Status',
      key: 'status',
      width: 2,
    },
    {
      text: '',
      textAlign: 'left',
      key: 'actions',
      width: 3,
    },
  ];

  const fulfillOrder = async item => {
    try {
      setProcessing(true);
      const payload = {
        allocations: [
          {
            quantity: item.quantity,
            allocationId: item.id,
          },
        ],
      };
      await OrderService.fulfillTote(item.order?.id, payload);
      ToastMessage.success('Order marked as fulfilled Successfully');
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="position-relative">
      {processing && <LoaderBar />}
      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        RowComponent={<SortedWaveRow fulfillOrder={fulfillOrder} />}
        headerOffSet={37}
      />
    </div>
  );
}
