import { Truck } from '@phosphor-icons/react';
import { AppTable } from '../../../components/AppTable';
import {
  BodyContainer,
  HeaderBar,
  PageTitle,
  TitleBarTitle,
  TitleHeaderBar,
} from '../../../elements';
import { ShipmentListHook } from '../../../hooks';
import { ShipmentRow } from '../../../page-components';
import { DesktopMode, MobileMode } from '../../../layouts/ResponsiveMedia';

function Header() {
  return (
    <>
      <MobileMode>
        <HeaderBar className="row">
          <div className="col-6 pt-2">
            <PageTitle className="d-flex align-items-center">
              <Truck size={25} className="mr-1" /> <span>Shipments</span>
            </PageTitle>
          </div>
        </HeaderBar>
      </MobileMode>
      <DesktopMode>
        <TitleHeaderBar className="row">
          <div className="d-flex col-8 align-items-center">
            <TitleBarTitle className="d-flex align-items-center">
              <Truck size={20} className="mr-2 icon" /> <span>Shipments</span>
            </TitleBarTitle>
          </div>
        </TitleHeaderBar>
      </DesktopMode>
    </>
  );
}

export function ShipmentListScreen() {
  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
  } = ShipmentListHook();

  const columns = [
    {
      text: 'Products',
      key: 'Products',
      width: 4,
      textAlign: 'left',
    },
    {
      text: 'Details',
      key: 'content',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Value',
      key: 'value',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Type',
      key: 'type',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Status',
      key: 'status',
      width: 3,
      textAlign: 'left',
    },
  ];

  return (
    <BodyContainer className="no-header">
      <Header />
      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        RowComponent={<ShipmentRow />}
        pageChanged={pageChanged}
        headerOffSet={0}
      />
    </BodyContainer>
  );
}
