import { TableCell, TableRow } from 'semantic-ui-react';
import { Check } from '@phosphor-icons/react';
import { useEffect, useMemo, useState } from 'react';
import { ProductPropertiesConverted } from '../common';
import { ItemMiniInput, ReadOnlyItemDetails } from './commonComponents';
import {
  CenterDropdownStyle,
  ColoredPill,
  MuteText,
  StyledButton,
} from '../../../elements';
import { CommonUtility, theme } from '../../../utility';
import { ShipmentItemLocationsHook } from '../../../hooks';
import { BorderTableCell, BorderTableRow } from '../../../components';

function Location({ location }) {
  return (
    <ColoredPill color={location?.zoneColor}>{location?.code}</ColoredPill>
  );
}

export function ShipmentItemRow({
  item,
  imperial,
  openRecievingPopup,
  warehouseId,
  onLocationChange,
  userValue,
  onUnitsRecievedChange,
}) {
  const [open, setOpen] = useState(true);

  const latestAttribute = useMemo(
    () => item.aliases?.[0]?.attributes || item.attributes || {},
    [item],
  );

  const isMultiple = useMemo(() => item?.aliases?.length > 1, [item]);

  const latestAlias = useMemo(
    () =>
      item.aliases?.find(x => CommonUtility.isValidObject(x.attributes)) ||
      item ||
      {},
    [item],
  );

  const { data, loading } = ShipmentItemLocationsHook(
    warehouseId,
    latestAlias?._id,
    latestAlias?.qty,
    latestAlias?.qty,
  );

  const itemValue = userValue[item._id];

  const allLocationAssigned = useMemo(() => {
    let allAliasReceived = true;
    item.aliases.forEach(alias => {
      if (!alias?.metadata?.receivedLocation) {
        allAliasReceived = false;
      }
    });
    return allAliasReceived;
  }, [item]);

  const allQtyRecieved = useMemo(() => {
    let allAliasReceived = true;
    item.aliases.forEach(alias => {
      if (!alias?.metadata?.checked_quantity) {
        allAliasReceived = false;
      }
    });
    return allAliasReceived;
  }, [item]);

  const sumOfUnitsAnticipated = useMemo(() => {
    let sum = 0;
    item.aliases?.forEach(alias => {
      sum += alias.qty;
    });
    return sum;
  }, [item]);

  return (
    <>
      <TableRow textAlign="left">
        <ReadOnlyItemDetails
          open={open}
          setOpen={setOpen}
          imperial={imperial}
          item={item}
          latestAttribute={latestAttribute}
        />
        <TableCell textAlign="center">
          <div className="d-flex justify-content-center">
            {isMultiple ? (
              <MuteText>Multiple</MuteText>
            ) : allLocationAssigned ? (
              <Location location={latestAlias?.receivedLocation} />
            ) : allQtyRecieved ? (
              <CenterDropdownStyle
                onChange={(e, { value }) =>
                  onLocationChange(item._id, latestAlias._id, value)
                }
                value={itemValue?.locations[latestAlias._id]}
                loading={loading}
                options={data}
                selection
              />
            ) : (
              <ColoredPill color={theme.colors.grey}>Counting</ColoredPill>
            )}
          </div>
        </TableCell>
        <TableCell>
          <div className="d-flex justify-content-center">
            {!isMultiple &&
              (allLocationAssigned ? (
                <ColoredPill color={theme.colors.green}>
                  <Check /> Stored
                </ColoredPill>
              ) : !allQtyRecieved ? (
                <div className="d-flex flex-column align-items-center">
                  <ItemMiniInput
                    noMax
                    onChange={value =>
                      onUnitsRecievedChange(item._id, latestAlias?._id, value)
                    }
                    value={itemValue?.received?.[latestAlias._id]}
                  />
                  <div className="d-flex text-nowrap mt-1">
                    <MuteText>Units Anticipated:</MuteText>
                    <span className="ml-1">{sumOfUnitsAnticipated}</span>
                  </div>
                </div>
              ) : (
                <StyledButton
                  onClick={() => {
                    openRecievingPopup(item, latestAlias);
                  }}
                  className="flex-btn"
                >
                  <Check className="mr-1" /> Mark as Received
                </StyledButton>
              ))}
          </div>
        </TableCell>
      </TableRow>
      {isMultiple && open && (
        <DetailVariantsRenderer
          openRecievingPopup={openRecievingPopup}
          onUnitsRecievedChange={onUnitsRecievedChange}
          imperial={imperial}
          item={item}
          itemValue={itemValue}
          onLocationChange={onLocationChange}
          warehouseId={warehouseId}
        />
      )}
    </>
  );
}

const DetailVariantsRenderer = ({
  item,
  imperial,
  openRecievingPopup,
  onUnitsRecievedChange,
  onLocationChange,
  itemValue,
  warehouseId,
}) =>
  item.aliases.map(variant => {
    const { data, loading } = ShipmentItemLocationsHook(
      warehouseId,
      variant?._id,
      variant?.qty,
      variant?.qty,
    );

    const alias = variant.alias;

    const locationAssigned = !!variant?.metadata?.receivedLocation;
    const qtyAssigned = !!variant?.metadata?.checked_quantity;

    useEffect(() => {
      if (CommonUtility.isValidArray(data)) {
        const existLocationInOptions = data.find(
          x => x.value === itemValue?.locations[variant._id],
        );
        if (!existLocationInOptions) {
          onLocationChange(item._id, variant._id, data[0]?.value);
        }
      }
    }, [data]);

    return (
      <BorderTableRow key={variant._id}>
        <BorderTableCell expand="true">
          <div className="border" /> {variant.alias}
        </BorderTableCell>
        <TableCell>
          <ProductPropertiesConverted
            attributes={{ alias, ...variant.attributes }}
            imperial={imperial}
          />
        </TableCell>
        <TableCell>
          <div className="d-flex justify-content-center">
            {!qtyAssigned ? (
              <ColoredPill color={theme.colors.grey}>Counting</ColoredPill>
            ) : locationAssigned ? (
              <Location location={variant.receivedLocation} />
            ) : (
              <CenterDropdownStyle
                onChange={(e, { value }) =>
                  onLocationChange(item._id, variant._id, value)
                }
                value={itemValue?.locations[variant._id]}
                loading={loading}
                options={data}
                selection
              />
            )}
          </div>
        </TableCell>
        <TableCell>
          <div className="d-flex justify-content-center">
            {!qtyAssigned ? (
              <div className="d-flex flex-column align-items-center">
                <ItemMiniInput
                  noMax
                  onChange={value =>
                    onUnitsRecievedChange(item._id, variant?._id, value)
                  }
                  value={itemValue?.received?.[variant._id]}
                />
                <div className="d-flex text-nowrap mt-1">
                  <MuteText>Units Anticipated:</MuteText>
                  <span className="ml-1">{variant.qty}</span>
                </div>
              </div>
            ) : !locationAssigned ? (
              <StyledButton
                onClick={() => {
                  openRecievingPopup(item, variant);
                }}
                className="flex-btn"
              >
                <Check className="mr-1" /> Mark Received
              </StyledButton>
            ) : (
              <ColoredPill color={theme.colors.green}>
                <Check /> Stored
              </ColoredPill>
            )}
          </div>
        </TableCell>
      </BorderTableRow>
    );
  });
