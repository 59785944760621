import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { GearSix, SignOut } from '@phosphor-icons/react';
import { useState } from 'react';
import { Loader } from 'semantic-ui-react';
import { MenuIconContainer } from './components';
import { DarkPopup, OrganisationAvatar } from '../components';
import { useAuth } from '../contexts/auth';
import { AccountService } from '../utility/services/account';
import { BrowserUtility, ToastMessage, UserService } from '../utility';
import { useTabletAndBelowMediaQuery } from './ResponsiveMedia';

const Header = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 40px;
  width: calc(100vw - 60px);
  display: flex;
  justify-content: end;
  padding: 5px 10px;
  column-gap: 10px;
`;

const OrganisationContainer = styled.div`
  display: flex;
  column-gap: 10px;
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
  }
  border-radius: 5px;

  &:hover {
    background: ${({ theme }) => theme.colors.primaryLight};
  }

  &.active {
    background: ${({ theme }) => theme.colors.primaryLight};
  }

  padding: 7px;
  cursor: pointer;
`;

const OrganisationList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.primaryLight};
  width: 30px;
  height: 30px;
  border-radius: 5px;
`;

export function SecurePrimaryHeader() {
  const [orgLoading, setOrgLoading] = useState(false);
  const { logout: providerLogout, currentOrg, user, setUserData } = useAuth();

  const logoutClick = () => {
    providerLogout();
  };

  const changeOrg = async item => {
    try {
      setOrgLoading(true);
      const response = await AccountService.switchOrganization(item._id);
      BrowserUtility.removeAll();
      setTimeout(() => {
        UserService.storeUser(response);
        setUserData(response);
        window.location.reload();
      }, [100]);
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setOrgLoading(false);
    }
  };

  const isMobile = useTabletAndBelowMediaQuery();

  if (isMobile) {
    return <></>;
  }

  return (
    <Header>
      <Link to="/app/settings">
        <MenuIconContainer className="small">
          <GearSix size={20} />
        </MenuIconContainer>
      </Link>
      <DarkPopup
        trigger={
          <MenuIconContainer onClick={logoutClick} className="small">
            <SignOut size={20} />
          </MenuIconContainer>
        }
      >
        Sign Out
      </DarkPopup>
      <DarkPopup
        trigger={
          <AvatarContainer className="cursor-pointer">
            {orgLoading ? (
              <Loader size="tiny" active inline inverted />
            ) : (
              <OrganisationAvatar name={currentOrg.name} mini />
            )}
          </AvatarContainer>
        }
        hoverable
        position="bottom right"
        basic
        offset={[0, -5]}
      >
        <OrganisationList>
          <b className="mb-2">Organizations</b>
          {user.organizations.map(org => (
            <OrganisationContainer
              className={org._id === currentOrg._id ? 'active' : ''}
              onClick={() => {
                if (!orgLoading && org._id !== currentOrg._id) {
                  changeOrg(org);
                }
              }}
              key={org._id}
              disabled={orgLoading}
            >
              <OrganisationAvatar name={org.name} mini />
              <span className="name">{org.name}</span>
            </OrganisationContainer>
          ))}
        </OrganisationList>
      </DarkPopup>
    </Header>
  );
}
