import { useMemo } from 'react';
import styled from 'styled-components';
import {
  CalendarCheck,
  CardsThree,
  Cube,
  Ruler,
  Scales,
  Truck,
} from '@phosphor-icons/react';
import {
  ConversionViewer,
  TextCopyToClipboard,
  TitleBackButton,
} from '../../../components';
import {
  BoldText,
  ColoredPill,
  HeaderBar,
  MuteText,
  PageTitle,
  TitleBarTitle,
  TitleHeaderBar,
} from '../../../elements';
import { CommonUtility, DateUtility, theme } from '../../../utility';
import { ConversionUtility } from '../../../utility/conversions';
import { CBMRenderer } from '../common';
import { DesktopMode, MobileMode } from '../../../layouts/ResponsiveMedia';

const TextHeader = styled(PageTitle)`
  max-width: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  .origin-name {
    display: inline-block;
    max-width: 35vw;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export function ShipmentDetailHeader({ data, imperial, summary, cbm }) {
  const cbf = useMemo(() => ConversionUtility.cbmTocbf(cbm), [cbm]);

  const destinationName = useMemo(() => {
    if (!data) return '';
    return data.destination?.name;
  }, [data]);

  const originName = useMemo(() => {
    if (!data) return '';
    return data.origins?.map(x => x.name)?.join(', ');
  }, [data]);

  return (
    <>
      <MobileMode>
        <HeaderBar className="row">
          <div className="col-9 d-flex flex-column">
            <div>
              <TextHeader>
                <Truck size={24} />
                <span className="mx-2">Shipment from</span>
                <span className="origin-name">{originName}</span>{' '}
                <span className="ml-2">to {destinationName}</span>
              </TextHeader>
              <div className="d-flex">
                <div className="mr-3">
                  <BoldText>
                    {CommonUtility.removeUnderscoreAndCapitalize(data?.status)}
                  </BoldText>
                </div>
                <MuteText className="mr-1 d-flex">
                  <span className="mr-1">ID</span>
                  <b>
                    <TextCopyToClipboard text={data?.display_id} />
                  </b>
                </MuteText>
                <MuteText className="mx-3">
                  <CBMRenderer imperial={imperial} cbf={cbf} cbm={cbm} />
                </MuteText>
                <MuteText>
                  Total Weight{' '}
                  <b>
                    <ConversionViewer
                      value={CommonUtility.roundNumber(summary?.grossWeight, 2)}
                      imperial={imperial}
                      originalUnit="kg"
                    />
                  </b>
                </MuteText>
                <MuteText className="mx-4">
                  Cartons{' '}
                  <b>
                    {CommonUtility.numberWithCommas(
                      summary?.cartons?.toFixed(),
                    )}
                  </b>
                </MuteText>
                <MuteText>
                  Unit Quantity{' '}
                  <b>{CommonUtility.numberWithCommas(summary.units)}</b>
                </MuteText>
              </div>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-end">
            <MuteText>Estimated Arrival</MuteText>
            <BoldText className="ml-1">
              <MuteText>
                {DateUtility.noOfDays(new Date(data?.ETA))} Days
              </MuteText>
            </BoldText>
          </div>
        </HeaderBar>
      </MobileMode>
      <DesktopMode>
        <TitleHeaderBar className="row align-items-top">
          <div className="d-flex col-8">
            <TitleBackButton />
            <TitleBarTitle className="d-flex align-items-center">
              <Truck size={20} className="mr-1 icon" />{' '}
              <span>{data?.display_id}</span>
            </TitleBarTitle>
            <ColoredPill color={theme.colors.grey} className="ml-4">
              <Ruler weight="fill" color={theme.colors.grey} />
              <CBMRenderer imperial={imperial} cbf={cbf} cbm={cbm} />
            </ColoredPill>
            <ColoredPill color={theme.colors.grey} className="ml-4">
              <Scales />
              <ConversionViewer
                value={CommonUtility.roundNumber(summary?.grossWeight, 2)}
                imperial={imperial}
                originalUnit="kg"
              />
            </ColoredPill>
            <ColoredPill color={theme.colors.grey} className="ml-4">
              <CardsThree />
              {CommonUtility.numberWithCommas(summary?.cartons?.toFixed())}{' '}
              Cartons
            </ColoredPill>
            <ColoredPill color={theme.colors.grey} className="ml-4">
              <Cube />
              {CommonUtility.numberWithCommas(summary.units)} Units
            </ColoredPill>
            <ColoredPill color={theme.colors.grey} className="ml-4">
              <CalendarCheck />
              {DateUtility.noOfDays(new Date(data?.ETA))} Days
            </ColoredPill>
          </div>
        </TitleHeaderBar>
      </DesktopMode>
    </>
  );
}
