import { TableCell, TableRow } from 'semantic-ui-react';
import styled from 'styled-components';
import { PencilSimple, TrashSimple } from '@phosphor-icons/react';
import { DateFormat, DateUtility, theme } from '../../utility';
import { ColoredPill, MuteText } from '../../elements';
import { ActionEditDots, ActionMenuItem } from '../../components';

const ValueContainer = styled.span`
  text-transform: capitalize;
  margin-left: 7px;
`;

const ActionEditTableRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    .action-container {
      opacity: 1 !important;
    }
  }
`;

function extractValue(obj, path) {
  const keyParts = path.split('.');
  let value = obj;

  for (const part of keyParts) {
    if (value && value.hasOwnProperty(part)) {
      value = value[part];
    } else {
      value = undefined;
      break;
    }
  }

  return value;
}

function extractName(path) {
  const parts = path.split('.');
  return parts[parts.length - 1]
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, str => str.toUpperCase());
}

export function WaveRuleRow({
  item,
  editRule,
  fieldsToGrasp,
  onDeleteWaveRule,
}) {
  return (
    <ActionEditTableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell>
        {DateUtility.formatDate(item.createdAt, DateFormat.dateTime)}
      </TableCell>
      <TableCell>
        <div>
          <MuteText>Zone Restrictions</MuteText>
          {item.zoneRestrictions?.map(zone => (
            <ValueContainer key={zone._id}>{zone.name}</ValueContainer>
          ))}
        </div>
        <div>
          <MuteText>Product Tags</MuteText>
          {item.productTags?.map(tag => (
            <ValueContainer key={tag}>{tag}</ValueContainer>
          ))}
        </div>
      </TableCell>
      <TableCell>
        <div>
          {fieldsToGrasp?.map(path => {
            const name = extractName(path);
            const value = extractValue(item, path);
            if (typeof value === 'object' || !value) {
              return <></>;
            }

            return (
              <div>
                <MuteText>{name}</MuteText>
                <ValueContainer>{value}</ValueContainer>
              </div>
            );
          })}
        </div>
      </TableCell>
      <TableCell>
        <ColoredPill
          color={item.isActive ? theme.colors.green : theme.colors.orange}
        >
          {item.isActive ? 'Active' : 'Not Active'}
        </ColoredPill>
        <ActionEditDots preventChangingBg on="hover" position="left center">
          <ActionMenuItem
            onClick={() => {
              editRule(item);
            }}
          >
            <PencilSimple />
            Edit
          </ActionMenuItem>
          <ActionMenuItem
            onClick={() => {
              onDeleteWaveRule(item);
            }}
            className="danger"
          >
            <TrashSimple />
            Delete
          </ActionMenuItem>
        </ActionEditDots>
      </TableCell>
    </ActionEditTableRow>
  );
}
