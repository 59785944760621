import styled from 'styled-components';
import { Plus } from '@phosphor-icons/react';
import { useState } from 'react';
import { AppTable, ConfirmationPopup } from '../../../../components';
import { TitleButton } from '../../../../elements';
import { PalletService, ToastMessage } from '../../../../utility';
import { PalletListWarehouseHook } from '../../../../hooks';
import {
  AddPalletPopup,
  PalletRow,
} from '../../../../page-components/settings';

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: ${({ zIndex }) => zIndex || '997'};
  transition: all 0.2s;
  height: 40px;
  width: 40vw;
  right: 130px;
  padding: 5px 0;
  display: flex;
  justify-content: end;
  button {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

function Header({ openPopup }) {
  return (
    <HeaderContainer>
      <TitleButton className="flex-btn" onClick={openPopup}>
        <Plus className="mr-1" /> Pallet
      </TitleButton>
    </HeaderContainer>
  );
}

export function PalletsTab({ warehouseId }) {
  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
    refresh,
  } = PalletListWarehouseHook(warehouseId, null);

  const [addRulePopup, setAddRulePopup] = useState(false);
  const [deletePalletPopup, setDeletePopup] = useState(null);
  const [processing, setProcessing] = useState(false);

  const columns = [
    {
      text: 'ID',
      key: 'id',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Assigned Zone',
      textAlign: 'left',
      key: 'details',
      width: 2,
    },
    {
      text: 'Size',
      textAlign: 'left',
      key: 'size',
      width: 3,
    },
    {
      text: 'Weight Limit',
      textAlign: 'left',
      key: 'weight_limit',
      width: 2,
    },
    {
      text: 'Status',
      textAlign: 'left',
      key: 'status',
      width: 2,
    },
  ];

  const openPopup = () => {
    setAddRulePopup(true);
  };

  const closePopup = () => {
    setAddRulePopup(false);
  };

  const editPallet = pallet => {
    setAddRulePopup(pallet);
  };

  const onDeletePallet = pallet => {
    setDeletePopup(pallet);
  };

  const onCloseDelete = () => {
    setDeletePopup(null);
  };

  const deletePallet = async () => {
    try {
      setProcessing(true);

      await PalletService.remove(deletePalletPopup._id);
      ToastMessage.success(
        `${deletePalletPopup.palletNumber} deleted successfully.`,
      );
      setDeletePopup(null);
      refresh();
    } catch (error) {
      ToastMessage.apiError(error?.error || error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <Header openPopup={openPopup} />

      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        RowComponent={
          <PalletRow editPallet={editPallet} onDeletePallet={onDeletePallet} />
        }
      />

      <AddPalletPopup
        onClose={closePopup}
        open={addRulePopup}
        refresh={refresh}
        warehouseId={warehouseId}
        item={addRulePopup}
      />

      <ConfirmationPopup
        onConfirm={deletePallet}
        onClose={onCloseDelete}
        open={deletePalletPopup}
        loading={processing}
        title="Delete Pallet"
        message={`Are you sure to delete ${deletePalletPopup?.palletNumber}`}
      />
    </div>
  );
}
