import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';
import { useMemo } from 'react';
import { Cube, Warehouse } from '@phosphor-icons/react';
import {
  BodyContainer,
  HeaderBar,
  MuteText,
  PageTitle,
  TitleBarTitle,
  TitleHeaderBar,
} from '../../../../elements/Common';
import { TabStyleMenu } from '../../../../elements/Tab';
import { CommonUtility, Tabs } from '../../../../utility';
import { OrderListTab } from './list';
import {
  FilterHook,
  OrderHook,
  useDebouncedMemo,
  WarehouseListHook,
} from '../../../../hooks';
import {
  FilterButton,
  FilterPill,
  SearchInput,
  TitleSearchInput,
} from '../../../../components';
import { DesktopMode, MobileMode } from '../../../../layouts/ResponsiveMedia';

function Header({
  loading,
  filters,
  updateAllFilter,
  finalFilter,
  setFinalValue,
  finalValue,
  activeIndex,
  setActiveIndex,
  setOpen,
  open,
  filterChanged,
}) {
  return (
    <>
      <MobileMode>
        <HeaderBar className="row tab-layout">
          <div className="col-6 pt-2">
            <PageTitle className="d-flex align-items-center">
              <Cube size={25} />
              <span className="ml-2">Orders</span>
            </PageTitle>
          </div>
          <div className="col-6 row justify-content-end">
            <SearchInput
              search={finalFilter.search}
              setSearch={search => filterChanged('search', search)}
              placeholder="Search by Order Id"
              className="mr-2"
            />
            <FilterButton
              setFinalFilter={updateAllFilter}
              loading={loading}
              filters={filters}
              finalFilter={finalFilter}
              setFinalvalue={setFinalValue}
              finalValue={finalValue}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              open={open}
              setOpen={setOpen}
            />
          </div>
        </HeaderBar>
      </MobileMode>
      <DesktopMode>
        <TitleHeaderBar className="row align-items-start">
          <div className="d-flex col-8 align-items-start">
            <TitleBarTitle className="d-flex align-items-center mt-1">
              <Cube size={20} className="mr-1 icon" /> <span>Orders</span>
            </TitleBarTitle>
            <div className="d-flex ml-2">
              <FilterButton
                setFinalFilter={updateAllFilter}
                loading={loading}
                filters={filters}
                finalFilter={finalFilter}
                setFinalvalue={setFinalValue}
                finalValue={finalValue}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
                open={open}
                setOpen={setOpen}
                titleBtn
              />
            </div>
          </div>
          <div className="col-4 d-flex justify-content-end">
            <TitleSearchInput
              search={finalFilter.search}
              setSearch={search => filterChanged('search', search)}
              placeholder="Search by Order Id"
            />
          </div>
        </TitleHeaderBar>
      </DesktopMode>
    </>
  );
}

const TabContainer = styled.div`
  margin-top: 30px;
`;

export function OrderListScreen() {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const currentTab = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 4);
    }
    return null;
  }, [pathname]);

  const { data: warehouseList, loading: warehouseLoading } =
    WarehouseListHook();
  const [searchParams] = useSearchParams();

  const warehouseOptions = useMemo(
    () =>
      warehouseList?.map(warehouse => ({
        value: warehouse._id,
        text: warehouse.name,
      })),
    [warehouseList],
  );

  const orderHookParams = OrderHook({ type: currentTab });

  const {
    filter: tableFilter,
    updateAllFilter,
    filterChanged,
  } = orderHookParams;

  const tabClicked = tabName => {
    navigate(`/app/fulfill/orders/${tabName}`);
  };

  const menus = [
    {
      text: 'Pending Fulfillment',
      value: Tabs.OrderTabs.pendingFulfillment,
    },
    {
      text: 'Shipped',
      value: Tabs.OrderTabs.shipped,
    },
  ];

  const filters = useDebouncedMemo(
    () => {
      const query = Object.fromEntries(searchParams.entries());
      const temp = { ...query };
      Object.keys(temp).forEach(key => {
        const final = CommonUtility.tryParseJSONObject(temp[key]);
        if (final) {
          temp[key] = final;
        }
      });

      const defaultValues = {
        ...temp,
      };

      const arr = [
        {
          name: 'warehouseId',
          label: 'Warehouse',
          icon: <Warehouse />,
          options: warehouseOptions,
          multiple: true,
          search: true,
          defaultValue: (defaultValues && defaultValues.warehouseId) || null,
        },
        {
          skip: true,
          name: 'page',
          must: true,
          defaultValue: (defaultValues && defaultValues.page) || 1,
          ignoreDefault: 1,
        },
        {
          skip: true,
          name: 'limit',
          must: true,
          defaultValue:
            defaultValues && CommonUtility.isValidNumber(defaultValues.limit)
              ? Number(defaultValues.limit)
              : 50,
          ignoreDefault: 50,
        },
      ];

      return arr;
    },
    [warehouseOptions, currentTab],
    500,
  );

  const filterHookParams = FilterHook(filters, tableFilter, currentTab);

  const { pillFilters, finalValue, removeFilter, openRelevantFilter } =
    filterHookParams;

  return (
    <BodyContainer className="no-header">
      <Header
        filters={filters}
        finalFilter={tableFilter}
        updateAllFilter={updateAllFilter}
        loading={warehouseLoading}
        filterChanged={filterChanged}
        {...filterHookParams}
      />
      <TabStyleMenu
        menus={menus}
        currentMenu={currentTab}
        onTabChange={tabClicked}
        className="no-header"
      />
      <TabContainer>
        <div className="d-flex justify-content-between">
          {pillFilters?.length > 0 && (
            <div className="d-flex align-items-center">
              <MuteText>Filters</MuteText>
              {filters.map(filter => (
                <FilterPill
                  data={filter}
                  key={filter.name}
                  userValue={finalValue}
                  removeFilter={removeFilter}
                  openRelevantFilter={openRelevantFilter}
                />
              ))}
            </div>
          )}
        </div>
        <Routes>
          <Route
            index
            element={<Navigate to={Tabs.OrderTabs.pendingFulfillment} />}
          />
          <Route path="*" element={<OrderListTab {...orderHookParams} />} />
        </Routes>
      </TabContainer>
    </BodyContainer>
  );
}
