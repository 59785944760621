import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { AuthGuard } from './guards';
import {
  AcceptInviteScreen,
  Error404,
  HomeScreen,
  InventoryDetailScreen,
  LoginScreen,
  OrderDetailScreen,
  OrderListScreen,
  PackingListScreen,
  ShipmentDetailScreen,
  ShipmentListScreen,
  WaveListScreen,
} from './screens';
import { AppLayout, SecureMenu, SecurePrimaryHeader } from './layouts';
import { SidebarProvider } from './contexts/sidebar';
import { RegisterScreen } from './screens/register';
import { OnboardingScreen } from './screens/app/onboarding';
import { SettingsDetailScreen } from './screens/app/settings';
import { WaveDetailScreen } from './screens/app/fulfill/waves/detail';
import { InventoryTransferListScreen } from './screens/app/inventory/transfer-list';
import { ExpirationListScreen } from './screens/app/inventory/expirations';
import {
  InventoryListScreen,
  ItemLogsScreen,
} from './screens/app/inventory/items';
import { GlobalProvider } from './contexts';
import { PickingDetailScreen } from './screens/app/fulfill/packing/id';
import { PickingCompletedDetailScreen } from './screens/app/fulfill/packing/completed-details';

function App() {
  const { pathname } = useLocation();
  const blockedUrls = ['/app/onboarding', '/app/onboarding/'];

  const hideSidebar = useMemo(() => blockedUrls.includes(pathname), [pathname]);

  useEffect(() => {
    const loadChatScriptByURL = () => {
      const url = '//js.hs-scripts.com/43733781.js';
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.async = true;

      document.body.appendChild(script);
    };

    loadChatScriptByURL();
    window.hsConversationsSettings = {
      loadImmediately: false,
    };
  }, []);

  return (
    <GlobalProvider>
      <Routes>
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/register" element={<RegisterScreen />} />
        <Route path="/accept-invite" element={<AcceptInviteScreen />} />
        <Route path="/" exact element={<Navigate to="/login" replace />} />
        <Route
          path="app"
          element={
            <AuthGuard>
              <SidebarProvider>
                <SecurePrimaryHeader hideSidebar={hideSidebar} />
                <SecureMenu hideSidebar={hideSidebar} />
                <AppLayout hideSidebar={hideSidebar} />
              </SidebarProvider>
            </AuthGuard>
          }
        >
          <Route path="onboarding" element={<OnboardingScreen />} />
          <Route path="settings/*" element={<SettingsDetailScreen />} />
          <Route path="inventory">
            <Route path="import/*" element={<InventoryDetailScreen />} />
            <Route path="list" element={<InventoryListScreen />} />
            <Route path="transfer" element={<InventoryTransferListScreen />} />
            <Route path="expirations" element={<ExpirationListScreen />} />
            <Route path="item/:id" element={<ItemLogsScreen />} />
          </Route>
          <Route path="shipments">
            <Route index element={<ShipmentListScreen />} />
            <Route path=":id" element={<ShipmentDetailScreen />} />
          </Route>
          <Route path="fulfill">
            <Route path="waves/list/*" element={<WaveListScreen />} />
            <Route path="waves/:id" element={<WaveDetailScreen />} />
            <Route path="orders/details/:id" element={<OrderDetailScreen />} />
            <Route path="orders/*" element={<OrderListScreen />} />
            <Route path="packing/list/*" element={<PackingListScreen />} />
            <Route
              path="packing/details/:containerNumber"
              element={<PickingDetailScreen />}
            />
            <Route
              path="packing/completed/:containerNumber"
              element={<PickingCompletedDetailScreen />}
            />
          </Route>
          <Route path="dashboard" element={<HomeScreen />} />
          <Route index element={<Navigate to="dashboard" replace />} />
        </Route>
        <Route path="*" element={<Error404 />} />
      </Routes>
    </GlobalProvider>
  );
}

export default App;
