import { TableCell, TableRow } from 'semantic-ui-react';
import {
  Circle,
  PencilSimple,
  RectangleDashed,
  TrashSimple,
} from '@phosphor-icons/react';
import styled from 'styled-components';
import { ColoredPill, MuteText } from '../../../../elements';
import { CommonUtility, ToteStatusColors } from '../../../../utility';
import { ActionEditDots, ActionMenuItem } from '../../../../components';

const ActionEditTableRow = styled(TableRow)`
  position: relative;

  .action-container {
    margin: auto;
    background: transparent;
    right: 10px;
    bottom: 0;
  }

  &:hover {
    .action-container {
      opacity: 1 !important;
    }
  }
`;

export function PalletRow({ item, editPallet, onDeletePallet }) {
  return (
    <ActionEditTableRow>
      <TableCell>{item.palletNumber}</TableCell>
      <TableCell>
        {item.zoneId?.code ? (
          <ColoredPill color={item.zoneId?.color}>
            <RectangleDashed /> {item.zoneId?.name}
          </ColoredPill>
        ) : (
          <MuteText>Not Set</MuteText>
        )}
      </TableCell>
      <TableCell>
        {item.dimensions?.length ? (
          <div>
            <span>{item.dimensions?.length}</span>
            <MuteText className="mx-1">x</MuteText>
            <span>{item.dimensions?.height}</span>
            <MuteText className="mx-1">x</MuteText>
            <span>{item.dimensions?.width}</span>
          </div>
        ) : (
          <MuteText>Not Set</MuteText>
        )}
      </TableCell>
      <TableCell>
        {item.dimensions?.weightLimit ? (
          item.dimensions.weightLimit
        ) : (
          <MuteText>Not Set</MuteText>
        )}
      </TableCell>
      <TableCell>
        <ColoredPill color={ToteStatusColors[item.status]}>
          <Circle weight="fill" />
          {CommonUtility.toTitleCase(item.status)}
        </ColoredPill>
        <ActionEditDots preventChangingBg on="hover" position="left center">
          <ActionMenuItem
            onClick={() => {
              editPallet(item);
            }}
          >
            <PencilSimple />
            Edit
          </ActionMenuItem>
          <ActionMenuItem
            onClick={() => {
              onDeletePallet(item);
            }}
            className="danger"
          >
            <TrashSimple />
            Delete
          </ActionMenuItem>
        </ActionEditDots>
      </TableCell>
    </ActionEditTableRow>
  );
}
