import { TableCell, TableRow } from 'semantic-ui-react';
import styled from 'styled-components';
import { Eye, Info } from '@phosphor-icons/react';
import { AppTable } from '../../../components/AppTable';
import { InventoryFeedsHook } from '../../../hooks';
import { CommonUtility, DateFormat, DateUtility } from '../../../utility';
import { DarkPopup } from '../../../components/Popup';

export function InventoryImportFeedTab() {
  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
  } = InventoryFeedsHook();
  const columns = [
    {
      text: 'Upload Time',
      key: 'upload_time',
      textAlign: 'left',
    },
    {
      text: 'Status',
      key: 'status',
      textAlign: 'left',
    },
    {
      text: 'File',
      key: 'file',
      textAlign: 'left',
    },
  ];

  return (
    <div>
      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        RowComponent={<FeedRow />}
        pageChanged={pageChanged}
        headerOffSet={0}
      />
    </div>
  );
}

const StatusText = styled.span`
  text-transform: capitalize;
`;

const FileHref = styled.a`
  display: flex;
  align-items: center;
`;

function formatDynamicObject(obj) {
  return Object.entries(obj)
    .map(([key, value]) => `${key}: ${value}`)
    .join(', ');
}

function FeedRow({ item }) {
  return (
    <TableRow>
      <TableCell>
        {DateUtility.formatDate(item.createdAt, DateFormat.dateTime)}
      </TableCell>
      <TableCell>
        <div className="d-flex align-items-center">
          <StatusText>{item.status}</StatusText>
          {CommonUtility.isValidObject(item?.results?.messages?.summary) && (
            <DarkPopup trigger={<Info className="ml-1 cursor-pointer" />}>
              {formatDynamicObject(item?.results?.messages?.summary)}
            </DarkPopup>
          )}
        </div>
      </TableCell>
      <TableCell>
        <FileHref href={item.url} download target="_blank">
          <Eye className="mr-2" size={18} />
          <span>View File</span>
        </FileHref>
      </TableCell>
    </TableRow>
  );
}
