import { Barcode } from '@phosphor-icons/react';
import { Placeholder } from 'semantic-ui-react';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppTable } from '../../../../components';
import { HeaderText, ShadowBox } from '../../../../elements';
import { PackagingListRow } from '../../../../page-components';
import { theme, ToastMessage } from '../../../../utility';
import { PackagingHook } from '../../../../hooks/packing';
import { useBarcodeScanner } from '../../../../hooks';
import { PackagingService } from '../../../../utility/services/packaging';

const StyledPlacholder = styled(Placeholder)`
  margin-top: -6px;
`;

export function PickListTab({ warehouseId }) {
  const {
    filter: tableFilter,
    filterChanged,
    loading: orderLoading,
    data: orderData,
    totalData,
    totalPages,
    pageChanged,
  } = PackagingHook(warehouseId);

  const navigate = useNavigate();

  const [barcodeValue, setBarcodeValue] = useState('');
  const [processing, setProcessing] = useState(false);

  const resetBarcode = () => {
    setBarcodeValue('');
  };

  useBarcodeScanner(scanValue => {
    setBarcodeValue(scanValue);
  });

  const checkData = async code => {
    setProcessing(true);
    try {
      await PackagingService.getPackingContainerDetails({ code });
      navigate(`/app/fulfill/packing/details/${barcodeValue}`);
    } catch (error) {
      if (error?.status === 404) {
        ToastMessage.error(
          'Container with such code not found. Kindly rescan the container.',
        );
      } else {
        ToastMessage.apiError(error);
      }
    } finally {
      resetBarcode();
      setProcessing(false);
    }
  };

  useEffect(() => {
    if (barcodeValue && !processing) {
      checkData(barcodeValue);
    }
  }, [barcodeValue]);

  const orderColumns = [
    {
      text: 'Wave ID',
      key: 'wave_id',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Status',
      key: 'status',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Created',
      key: 'created',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Container',
      key: 'container',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Contents',
      key: 'contents',
      width: 2,
      textAlign: 'left',
    },
    {
      text: '',
      key: 'action',
      width: 2,
      textAlign: 'left',
    },
  ];

  return (
    <div>
      <ShadowBox className="d-flex align-item-center">
        {processing ? (
          <StyledPlacholder fluid className="w-100">
            <Placeholder.Line />
            <Placeholder.Line />
          </StyledPlacholder>
        ) : (
          <>
            <Barcode color={theme.colors.grey} size={32} />
            <HeaderText className="ml-2 mt-1">
              Scan a Container to Get Started
            </HeaderText>
          </>
        )}
      </ShadowBox>
      <AppTable
        columns={orderColumns}
        loading={orderLoading}
        RowComponent={<PackagingListRow />}
        headerOffSet={32}
        data={orderData}
        totalData={totalData}
        totalPages={totalPages}
        pageChanged={pageChanged}
        currentPage={tableFilter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={tableFilter.limit}
      />
    </div>
  );
}
