import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { useSidebar } from '../contexts/sidebar';
import {
  useSmallScreenAndBelowMediaQuery,
  useTabletAndBelowMediaQuery,
} from './ResponsiveMedia';

const BoxContainer = styled.div`
  padding: 10px;
  background: ${({ theme }) => theme.colors.primary};
`;

const ContentContainer = styled.div`
  padding: ${({ padding }) => padding || 0}px;
  overflow-x: hidden;
  overflow-y: auto;
  transition: all 0.2s;
  background: ${({ theme }) => theme.colors.box};
  border-radius: 10px;
  margin-left: ${({ isCollapsed }) => (isCollapsed ? '50px' : '235px')};

  margin-top: 30px;
  max-height: calc(100vh - 50px);
  min-height: calc(100vh - 50px);

  &.tab-layout {
    max-height: calc(100vh - 150px);
    min-height: calc(100vh - 150px);
    margin-top: 100px;
  }

  &.no-header {
    border-radius: 10px;
    margin-top: 0px;
    max-height: calc(100vh - 50px);
    min-height: calc(100vh - 50px);
  }
`;

const SimpleLayout = styled.div`
  background: ${({ theme }) => theme.colors.box};
  min-height: 100vh;
`;

const MobileLayout = styled.div`
  background: ${({ theme }) => theme.colors.box};
  min-height: 100vh;
  padding-top: 70px;
  max-width: 100vw;
  overflow-x: hidden;
`;

export function AppLayout({ hideSidebar }) {
  const { isCollapsed } = useSidebar();
  const isTabletAndBelow = useSmallScreenAndBelowMediaQuery();
  const isMobile = useTabletAndBelowMediaQuery();

  if (hideSidebar) {
    return (
      <SimpleLayout>
        <Outlet />
      </SimpleLayout>
    );
  }

  if (isMobile) {
    return (
      <MobileLayout>
        <Outlet />
      </MobileLayout>
    );
  }

  return (
    <BoxContainer>
      <ContentContainer isCollapsed={isCollapsed || isTabletAndBelow}>
        <Outlet />
      </ContentContainer>
    </BoxContainer>
  );
}
