import { Barcode, Package, Scales, X } from '@phosphor-icons/react';
import styled from 'styled-components';
import { useMemo } from 'react';
import {
  HeaderText,
  HoverBorderButton,
  MuteBorder,
  MuteText,
  ShadowBox,
} from '../../../elements';
import { CommonUtility, theme } from '../../../utility';
import { ItemImage, LoaderBar } from '../../../components';

const PlaceholderContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 20px;
`;

const ItemsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const TitleContainer = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;

export function PackingDetailPacks({ items, isPallet, unPackItem, loading }) {
  const packedItems = useMemo(() => {
    if (CommonUtility.isValidArray(items)) {
      return items?.filter(x =>
        isPallet ? x.status === 'shipped' : x.status === 'packed',
      );
    }
    return [];
  }, [items, isPallet]);

  return (
    <div className="col-6 pr-0">
      <ShadowBox className="ml-1 h-100 d-flex flex-column">
        {loading && <LoaderBar />}

        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <Package size={24} color={theme.colors.grey} />
            <HeaderText className="mx-1">
              {isPallet ? 'Shipped' : 'Packed'}
            </HeaderText>
          </div>
          <div className="d-flex align-items-center">
            <Scales color={theme.colors.grey} size={20} />
            <span className="ml-2">23Kg</span>
          </div>
        </div>
        {CommonUtility.isValidArray(packedItems) ? (
          <ItemsContainer>
            {packedItems?.map((item, index) => (
              <>
                <div
                  key={item._id}
                  className="d-flex align-items-center justify-content-between"
                >
                  <div className="d-flex align-items-center">
                    <ItemImage
                      imageKey="p_images"
                      displayImageKey="display_image"
                      item={item?.productId}
                      size="mini"
                    />
                    <div className="d-flex flex-column ml-2">
                      <TitleContainer>
                        {item?.productId?.p_title}
                      </TitleContainer>
                      <div className="d-flex">
                        <MuteText>SKU</MuteText>
                        <span className="ml-2">{item?.productId?.p_sku}</span>
                      </div>
                      <div className="d-flex">
                        <MuteText>UPC</MuteText>
                        <span className="ml-2">{item?.productId?.upc}</span>
                      </div>
                    </div>
                  </div>
                  {!isPallet && (
                    <HoverBorderButton
                      onClick={() => unPackItem(item)}
                      size="mini"
                      className="flex-btn p-2"
                    >
                      <X size={17} />
                    </HoverBorderButton>
                  )}
                </div>
                {index !== packedItems?.length - 1 && <MuteBorder />}
              </>
            ))}
          </ItemsContainer>
        ) : (
          <PlaceholderContainer>
            <div className="d-flex flex-column align-items-center">
              <Barcode size={36} color={theme.colors.grey} />
              <HeaderText>Scan an Item to Pack</HeaderText>
            </div>
          </PlaceholderContainer>
        )}
      </ShadowBox>
    </div>
  );
}
