import { Circle } from '@phosphor-icons/react';
import { ColoredPill } from '../../elements';
import { CommonUtility, PackagingStatus, theme } from '../../utility';

export function PackingStatus({ status }) {
  const statusColors = {
    [PackagingStatus.inTransit]: theme.colors.purple,
    [PackagingStatus.readyToPack]: theme.colors.orange,
  };

  return (
    <ColoredPill color={statusColors[status]}>
      <Circle weight="fill" color={statusColors[status]} />
      {CommonUtility.toTitleCase(status)}
    </ColoredPill>
  );
}
