import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { WaveListHook } from '../../../../../hooks';
import { useWavesFilterContext } from '.';
import {
  ActionEditDots,
  ActionMenuItem,
  AppListPlaceholder,
  TablePagination,
} from '../../../../../components';
import { ShadowBox } from '../../../../../elements';
import { WaveSearchInput } from '../../../../../page-components/waves/WaveSearch';
import { MobileWaveRow } from '../../../../../page-components';
import { useAuth } from '../../../../../contexts';
import { CacheUtility } from '../../../../../utility';

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 67px;
  width: 100vw;
  z-index: 1000;
  padding: 0 10px;
`;

const userTypeKey = 'userTypeWaveList';

function Header({ adminView, toggleAdminView, setAdminView }) {
  useEffect(() => {
    const data = CacheUtility.get(userTypeKey);
    if (data?.isAdmin) {
      setAdminView(data?.isAdmin);
    }
  }, []);

  return (
    <StyledHeader className="d-flex align-items-center justify-content-end">
      <ActionEditDots
        preventChangingBg
        on="hover"
        position="bottom left"
        showByDefault
        offset={[0, -10]}
        inline
      >
        <ActionMenuItem disabled={adminView} onClick={toggleAdminView}>
          Admin View {adminView === true && '(Selected)'}
        </ActionMenuItem>
        <ActionMenuItem disabled={!adminView} onClick={toggleAdminView}>
          Picker View {adminView !== true && '(Selected)'}
        </ActionMenuItem>
      </ActionEditDots>
    </StyledHeader>
  );
}

export function MobileWaveList({ setTableSearch }) {
  const { warehouseId, search } = useWavesFilterContext();
  const { user } = useAuth();
  const navigate = useNavigate();

  const [adminView, setAdminView] = useState(false);

  const statuses = useMemo(
    () => encodeURI(['ready', 'in_progress', 'sorting', 'sorted']),
    [],
  );

  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
  } = WaveListHook(statuses, warehouseId, search);

  useEffect(() => {
    if (adminView) {
      filterChanged('assignedTo', null);
    } else {
      filterChanged('assignedTo', user._id);
    }
  }, [adminView, user]);

  const onResultClick = item => {
    navigate(`/app/fulfill/waves/${item._id}`);
  };

  const columns = [
    {
      text: 'Wave Number',
      key: 'wave-number',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Status',
      key: 'status',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Created',
      key: 'created',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Warehouse',
      key: 'warehouse',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Rule',
      key: 'rule',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Picker',
      key: 'picker',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Contents',
      textAlign: 'left',
      key: 'details',
      width: 3,
    },
  ];

  const toggleAdminView = () => {
    setAdminView(!adminView);
    CacheUtility.save(userTypeKey, { isAdmin: !adminView });
  };

  return (
    <div>
      <Header
        setAdminView={setAdminView}
        toggleAdminView={toggleAdminView}
        adminView={adminView}
      />
      <WaveSearchInput
        onResultClick={onResultClick}
        setTableSearch={setTableSearch}
        warehouseId={warehouseId}
        inputClassName="mb-4 w-100"
      />
      {loading ? (
        <AppListPlaceholder />
      ) : data?.length > 0 ? (
        <ListContainer>
          {data?.map(wave => (
            <MobileWaveRow key={wave._id} item={wave} />
          ))}
        </ListContainer>
      ) : (
        <ShadowBox>No Waves</ShadowBox>
      )}
      <TablePagination
        colSpan={columns.length}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        totalData={totalData}
      />
    </div>
  );
}
