import { useMemo, useState } from 'react';
import { Plus } from '@phosphor-icons/react';
import styled from 'styled-components';
import { AppTable, ConfirmationPopup } from '../../../../components';
import { WaveRuleListHook, WaveRulesMetadataHook } from '../../../../hooks';
import { WaveRuleRow } from '../../../../page-components/waves/WaveRuleRow';
import { AddWaveRulePopup } from '../../../../page-components/waves';
import { TitleButton } from '../../../../elements';
import { ToastMessage, WaveService } from '../../../../utility';

const HeaderContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: ${({ zIndex }) => zIndex || '997'};
  transition: all 0.2s;
  height: 40px;
  width: 40vw;
  right: 130px;
  padding: 5px 0;
  display: flex;
  justify-content: end;
  button {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;
function Header({ openPopup }) {
  return (
    <HeaderContainer>
      <TitleButton className="flex-btn" onClick={openPopup}>
        <Plus className="mr-1" /> Wave Rule
      </TitleButton>
    </HeaderContainer>
  );
}

export function WaveRulesTab({ warehouseId }) {
  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
    refresh,
  } = WaveRuleListHook(warehouseId);
  const { data: rulesMetadata } = WaveRulesMetadataHook();

  const [addRulePopup, setAddRulePopup] = useState(false);
  const [deleteWaveRulePopup, setDeletePopup] = useState(null);
  const [processing, setProcessing] = useState(false);

  const columns = [
    {
      text: 'Name',
      key: 'name',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Created',
      key: 'created',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Details',
      key: 'status',
      textAlign: 'left',
      width: 3,
    },
    {
      text: 'Threshold',
      textAlign: 'left',
      key: 'details',
      width: 2,
    },
    {
      text: 'Active',
      textAlign: 'left',
      key: 'active',
      width: 2,
    },
  ];

  const openPopup = () => {
    setAddRulePopup(true);
  };
  const closePopup = () => {
    setAddRulePopup(false);
  };

  const editRule = rule => {
    setAddRulePopup(rule);
  };

  const fieldsToGrasp = useMemo(() => {
    let fields = [];
    Object.values(rulesMetadata || {}).forEach(category => {
      Object.values(category).forEach(tag => {
        if (tag.value) {
          fields.push(tag.value);
        }
      });
    });
    return fields;
  }, [rulesMetadata]);

  const onDeleteWaveRule = tote => {
    setDeletePopup(tote);
  };

  const onCloseDelete = () => {
    setDeletePopup(null);
  };

  const deleteWaveRule = async () => {
    try {
      setProcessing(true);
      const res = await WaveService.deleteWaveRule(deleteWaveRulePopup._id);
      if (res?.status !== 'deleted' && res?.message) {
        ToastMessage.warning(res?.message);
      } else {
        ToastMessage.success(
          `${deleteWaveRulePopup.name} deleted successfully.`,
        );
      }
      setDeletePopup(null);
      refresh();
    } catch (error) {
      ToastMessage.apiError(error);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div>
      <Header openPopup={openPopup} />
      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        RowComponent={
          <WaveRuleRow
            onDeleteWaveRule={onDeleteWaveRule}
            fieldsToGrasp={fieldsToGrasp}
            editRule={editRule}
          />
        }
      />
      <AddWaveRulePopup
        onClose={closePopup}
        open={addRulePopup}
        item={addRulePopup}
        refresh={refresh}
        warehouseId={warehouseId}
        rulesMetadata={rulesMetadata}
      />
      <ConfirmationPopup
        onConfirm={deleteWaveRule}
        onClose={onCloseDelete}
        open={deleteWaveRulePopup}
        loading={processing}
        title="Delete Wave Rule"
        message={`Are you sure to delete ${deleteWaveRulePopup?.name}`}
      />
    </div>
  );
}
