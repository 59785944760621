import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { Plus } from '@phosphor-icons/react';
import styled from 'styled-components';
import { WarehouseDetailHook } from '../../../hooks';
import {
  AddWarehousePopup,
  WarehouseDetailTab,
} from '../../../page-components';
import { CommonUtility } from '../../../utility';
import { WarehouseZonesHook } from '../../../hooks/zone';
import { ZoneTab } from './zones';
import { TitleButton } from '../../../elements';

const WarehouseContext = createContext();

const Header = styled.div`
  position: fixed;
  top: 0;
  z-index: ${({ zIndex }) => zIndex || '997'};
  transition: all 0.2s;
  height: 40px;
  width: 40vw;
  right: 130px;
  padding: 5px 0;
  display: flex;
  justify-content: end;
  button {
    color: ${({ theme }) => theme.colors.white} !important;
  }
`;

export function WarehouseTab({ warehouseId, onCreateWarehouse }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const openPopup = () => {
    setOpen(true);
  };

  const closePopup = () => {
    setOpen(false);
  };

  const {
    data,
    loading,
    refresh: refreshWarehouse,
  } = WarehouseDetailHook(warehouseId);

  const {
    data: zones,
    loading: zoneLoading,
    refresh: zoneRefresh,
  } = WarehouseZonesHook(warehouseId);

  const zoneOptions = useMemo(() => {
    if (CommonUtility.isValidArray(zones)) {
      return zones?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [zones]);

  const zoneId = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 6);
    }
    return null;
  }, [pathname]);

  const inToZone = useMemo(() => {
    if (pathname) {
      return pathname.includes('zones');
    }
    return false;
  }, [pathname]);

  const onChangeZone = zoneId => {
    navigate(`/app/settings/warehouse/${warehouseId}/zones/${zoneId}`);
  };

  useEffect(() => {
    if (CommonUtility.isValidArray(zoneOptions) && inToZone && warehouseId) {
      const isInOptions = zoneOptions.find(x => x.value === zoneId);
      if (!isInOptions) {
        onChangeZone(zoneOptions[0]?.value);
      }
    }
  }, [zoneOptions, zoneId, inToZone, warehouseId]);

  return (
    <WarehouseContext.Provider
      value={{
        warehouseData: data,
        warehouseLoading: loading,
        refreshWarehouse,
        warehouseId,
      }}
    >
      {!inToZone && (
        <Header>
          <TitleButton size="mini" className="flex-btn" onClick={openPopup}>
            <Plus className="mr-1" />
            Warehouse
          </TitleButton>
        </Header>
      )}
      <div className="pt-2">
        <Routes>
          <Route
            path=":id"
            element={
              <WarehouseDetailTab
                zones={zones}
                zoneLoading={zoneLoading}
                zoneRefresh={zoneRefresh}
              />
            }
          />
          <Route
            path=":warehouseId/zones/:id/*"
            element={
              <ZoneTab
                warehouseId={warehouseId}
                onChangeZone={onChangeZone}
                zoneId={zoneId}
                zoneOptions={zoneOptions}
              />
            }
          />
        </Routes>
        <AddWarehousePopup
          onCreateWarehouse={onCreateWarehouse}
          open={open}
          onClose={closePopup}
        />
      </div>
    </WarehouseContext.Provider>
  );
}

export const useWarehouseDetails = () => useContext(WarehouseContext);
