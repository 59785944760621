import { Package } from '@phosphor-icons/react';
import { useMemo, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import styled from 'styled-components';
import {
  BodyContainer,
  HeaderBar,
  PageTitle,
  TitleBarTitle,
  TitleHeaderBar,
} from '../../../../elements/Common';
import { DesktopMode, MobileMode } from '../../../../layouts/ResponsiveMedia';
import { PickListTab } from './list';
import { TitleBackButton, WarehouseDropdown } from '../../../../components';
import { WarehouseListHook } from '../../../../hooks';
import { CommonUtility, Tabs } from '../../../../utility';
import { TabStyleMenu } from '../../../../elements/Tab';
import { CompletePickListTab } from './complete-list';

const TabContainer = styled.div`
  margin-top: 45px;
`;

function Header({
  warehouseOptions,
  warehouseLoading,
  warehouseId,
  setWarehouseId,
  defaultWarehouse,
  isCompletedTab,
}) {
  return (
    <>
      <MobileMode>
        <HeaderBar className="row tab-layout">
          <div className="col-6 pt-2">
            <PageTitle className="d-flex align-items-center">
              <Package size={25} />
              <span className="ml-2">Packing</span>
            </PageTitle>
          </div>
        </HeaderBar>
      </MobileMode>
      <DesktopMode>
        <TitleHeaderBar className="row align-items-start">
          <div className="d-flex col-8 align-items-start">
            {isCompletedTab && (
              <TitleBackButton url="/app/fulfill/packing/list/to-pack" />
            )}
            <TitleBarTitle className="d-flex align-items-center mt-1">
              <Package size={20} className="mr-1 icon" /> <span>Packing</span>
            </TitleBarTitle>
            <div className="d-flex ml-2">
              <WarehouseDropdown
                warehouseOptions={warehouseOptions}
                warehouseId={warehouseId}
                warehouseLoading={warehouseLoading}
                setWarehouseId={setWarehouseId}
                defaultWarehouseId={defaultWarehouse}
                titleMode
              />
            </div>
          </div>
        </TitleHeaderBar>
      </DesktopMode>
    </>
  );
}

export function PackingListScreen() {
  const [warehouseId, setWarehouseId] = useState('');

  const { data: warehouseData, loading: warehouseLoading } =
    WarehouseListHook();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const currentTab = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 5);
    }
    return null;
  }, [pathname]);

  const warehouseOptions = useMemo(() => {
    if (CommonUtility.isValidArray(warehouseData)) {
      return warehouseData?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [warehouseData]);

  const defaultWarehouse = useMemo(
    () =>
      CommonUtility.isValidArray(warehouseData) ? warehouseData[0]._id : null,
    [warehouseData],
  );

  const tabClicked = tabName => {
    navigate(`/app/fulfill/packing/list/${tabName}`);
  };

  const menus = [
    {
      text: 'To Pack',
      value: Tabs.PackingTabs.toPack,
    },
    {
      text: 'Complete',
      value: Tabs.PackingTabs.complete,
    },
  ];

  return (
    <BodyContainer className="no-header">
      <Header
        warehouseOptions={warehouseOptions}
        warehouseLoading={warehouseLoading}
        warehouseId={warehouseId}
        setWarehouseId={setWarehouseId}
        defaultWarehouse={defaultWarehouse}
        isCompletedTab={currentTab === Tabs.PackingTabs.complete}
      />
      <TabStyleMenu
        menus={menus}
        currentMenu={currentTab}
        onTabChange={tabClicked}
        className="no-header"
      />
      <TabContainer>
        <Routes>
          <Route index element={<Navigate to={Tabs.PackingTabs.toPack} />} />
          <Route
            path={Tabs.PackingTabs.toPack}
            element={<PickListTab warehouseId={warehouseId} />}
          />
          <Route
            path={Tabs.PackingTabs.complete}
            element={<CompletePickListTab warehouseId={warehouseId} />}
          />
        </Routes>
      </TabContainer>
    </BodyContainer>
  );
}
