import {
  Check,
  MapPin,
  Package,
  Printer,
  Truck,
  UserCircle,
} from '@phosphor-icons/react';
import { TitleBackButton } from '../../../components';
import {
  ColoredPill,
  HeaderBar,
  HoverBorderButton,
  StyledButton,
  TitleBarTitle,
  TitleHeaderBar,
} from '../../../elements';
import { DesktopMode } from '../../../layouts/ResponsiveMedia';
import { CommonUtility, theme } from '../../../utility';

export function PackingDetailHeader({
  data,
  isPallet,
  allPacked,
  fulfillTote,
  shipped,
  print,
  printing,
  showActions = true,
}) {
  return (
    <>
      <DesktopMode>
        <TitleHeaderBar className="row align-items-top">
          <div className="d-flex col-8">
            <TitleBackButton />
            <TitleBarTitle className="d-flex align-items-center">
              <Package size={20} className="mr-1 icon" />{' '}
              <span>
                {data?.order?.marketplace_order_display_id ||
                  data?.waves?.[0]?.waveId?.waveNumber}
              </span>
            </TitleBarTitle>
          </div>
        </TitleHeaderBar>
      </DesktopMode>
      <HeaderBar className="row">
        <div className="col-9 row align-items-center">
          {CommonUtility.isValidObject(data?.order) && (
            <>
              <ColoredPill color={theme.colors.grey} className="">
                <UserCircle size={18} />
                {data?.order?.shipping_address?.name}
              </ColoredPill>
              <ColoredPill color={theme.colors.grey} className="mx-3">
                <MapPin size={18} />
                {CommonUtility.getLongAddress(
                  data?.order?.shipping_address || {},
                  false,
                )}
              </ColoredPill>
            </>
          )}
          <ColoredPill color={theme.colors.grey}>
            <Truck size={18} />
            {CommonUtility.toTitleCase(data?.status)}
          </ColoredPill>
        </div>
        {!isPallet && showActions && (
          <div className="col-3 row justify-content-end">
            <HoverBorderButton
              loading={printing}
              onClick={print}
              className="flex-btn"
            >
              <Printer className="mr-1" /> Print
            </HoverBorderButton>
            {shipped ? (
              <ColoredPill color={theme.colors.green}>Shipped</ColoredPill>
            ) : (
              <StyledButton
                onClick={fulfillTote}
                disabled={!allPacked}
                className="flex-btn"
              >
                <Check className="mr-2" />
                Fulfill
              </StyledButton>
            )}
          </div>
        )}
      </HeaderBar>
    </>
  );
}
