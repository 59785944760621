import { useState } from 'react';
import { PalletService } from '../utility';
import { useDebouncedEffect } from './debounce';

export const PalletListWarehouseHook = (
  warehouseId,
  status = 'ready_to_pack',
  shouldFetch = true,
) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [refreshKey, setRefreshKey] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    page: 1,
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const params = {
        ...filter,
        warehouseId,
      };
      if (status) {
        params.status = status;
      }
      const res = await PalletService.get(params);
      setData(res?.data || []);
      setTotalData(res?.total);
      setTotalPages(res?.totalPages);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useDebouncedEffect(
    () => {
      if (warehouseId && shouldFetch) fetchData();
    },
    [filter, warehouseId, refreshKey, shouldFetch],
    100,
  );

  const pageChanged = page => {
    const temp = {
      ...filter,
      page,
    };
    setFilter({ ...temp });
  };

  const filterChanged = (key, value) => {
    const temp = {
      ...filter,
      page: 1,
      [key]: value,
    };
    setFilter({ ...temp });
  };

  const refresh = () => {
    setRefreshKey(refreshKey => refreshKey + 1);
  };

  return {
    data,
    loading,
    error,
    totalData,
    totalPages,
    filter,
    pageChanged,
    filterChanged,
    refresh,
  };
};
