import styled from 'styled-components';
import { cloneElement, Fragment } from 'react';
import { TablePagination } from './TablePagination';
import { MuteText } from '../elements';
import { AppTableRowPlaceholder } from './Placeholder';

const Table = styled.div`
  box-shadow: 0 5px 25px rgba(57, 57, 72, 0.05) !important;
  background: white;
  border-radius: 5px;
  font-size: 12px;

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col {
    padding-left: 0px;
  }
`;

const TableBody = styled.div`
  .mobile-row {
    padding: 5px;
  }
`;

const TableHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  padding: 10px;
`;

const Border = styled.div`
  background: ${({ theme }) => theme.colors.box};
  height: 1px;
  width: 100%;
`;

const TableHeaderCell = styled.div``;

export function MobileTable({
  columns,
  totalPages,
  currentPage,
  pageSizeChanged,
  totalData,
  pageSize,
  pageChanged,
  RowComponent,
  keyField,
  data,
  loading,
  noDataMessage = 'No Data',
}) {
  return (
    <>
      <Table className={loading ? 'pb-1' : ''}>
        <TableHeader className="row">
          {columns.map(column => (
            <TableHeaderCell
              key={column.text}
              className={`col-${column.width || 1}`}
            >
              <MuteText>{column.text}</MuteText>
            </TableHeaderCell>
          ))}
        </TableHeader>
        <TableBody>
          {loading ? (
            <AppTableRowPlaceholder colSpan={columns.length} catalogMobile />
          ) : (
            (data || []).map((item, index) => (
              <Fragment key={item[keyField]}>
                {cloneElement(RowComponent, {
                  ...RowComponent.props,
                  item,
                  index,
                })}
                {index + 1 !== data?.length && <Border />}
              </Fragment>
            ))
          )}
          {!loading && (data || []).length === 0 && (
            <div className="row mobile-row">
              <div className="col-12">{noDataMessage}</div>
            </div>
          )}
        </TableBody>
      </Table>
      <TablePagination
        colSpan={columns.length}
        totalPages={totalPages}
        currentPage={currentPage}
        pageSizeChanged={pageSizeChanged}
        pageSize={pageSize}
        pageChanged={pageChanged}
        totalData={totalData}
      />
    </>
  );
}
