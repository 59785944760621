import {
  ArrowsHorizontal,
  CaretDown,
  Check,
  Equals,
  NotEquals,
  X,
} from '@phosphor-icons/react';
import {
  useRef,
  useState,
  useEffect,
  isValidElement,
  cloneElement,
  useMemo,
} from 'react';
import ReactDatePicker from 'react-datepicker';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { HoverBorderButton, MuteText, StyledButton } from '../elements';
import { CommonUtility, DateFormat, DateUtility } from '../utility';
import { NumberMaskInput } from '../elements/CustomMaskInput';
import {
  CustomDateInput,
  DatepickerContainer,
  DateRangeInputContainer,
} from '../elements/Datepicker';
import { Flag } from '../elements/Flag';

const SelectionContainer = styled.div`
  box-shadow: 0px 5px 25px rgba(57, 57, 72, 0.05);
  background: ${({ theme }) => theme.colors.white};
  min-width: 250px;
  border-radius: 5px;
  .pointer {
    cursor: pointer;
  }
`;

const FilterHoverButton = styled(HoverBorderButton)`
  position: relative;
  height: 40px !important;
`;

const FilterTitleButton = styled(HoverBorderButton)`
  position: relative;
  height: 30px !important;
  color: ${({ theme }) => theme.colors.white} !important;
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.box};
`;
export const filterTypes = {
  is: 'EQUAL',
  isNot: 'NOT_EQUAL',
};

const CancelButton = styled(X)`
  position: absolute;
  right: 8px;
  &:hover {
    color: ${({ theme }) => theme.colors.grey};
  }
`;

function useOutsideAlerter(ref, onClickOuside) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOuside();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

const filterOptions = [
  {
    key: filterTypes.is,
    value: filterTypes.is,
    text: 'Equals',
    conditionImage: <Equals size={8} weight="bold" />,
  },
  {
    key: filterTypes.isNot,
    value: filterTypes.isNot,
    text: 'Does Not Equal',
    conditionImage: <NotEquals height={8} />,
  },
];

export function FilterButton({
  filters,
  loading,
  setFinalFilter,
  setFinalvalue,
  finalValue,
  activeIndex,
  setActiveIndex,
  open,
  setOpen,
  titleBtn,
}) {
  const [userValue, setUserValue] = useState({});
  const [localFilter, setLocalFilter] = useState(null);

  const applyFilter = (customValue = null) => {
    const _userValue = customValue || userValue;
    let hasFilters = false;

    for (const filterKey in _userValue) {
      if (!_userValue[filterKey].skip) {
        if (_userValue[filterKey].value) {
          hasFilters = true;
          break;
        }
      }
    }

    if (hasFilters) {
      setFinalvalue(_userValue);
    }
  };

  useEffect(() => {
    try {
      setFinalFilter(localFilter ? JSON.parse(localFilter) : {});
    } catch (error) {
      // console.log('error', error);
    }
  }, [localFilter]);

  useEffect(() => {
    if (Object.keys(finalValue).length < 1) return;

    const _userValue = { ...finalValue };
    const finalFilter = {};
    Object.keys(_userValue).forEach(name => {
      if (_userValue[name].filterType === 'date') {
        if (Array.isArray(_userValue[name].value)) {
          finalFilter[name] = _userValue[name].value
            .map(x => DateUtility.formatDate(x, DateFormat.yearMonthDate))
            .join(',');
        } else {
          finalFilter[name] = DateUtility.formatDate(
            _userValue[name].value,
            DateFormat.yearMonthDate,
          );
        }
      } else if (Array.isArray(_userValue[name].value)) {
        if (_userValue[name].value[0] === '-1') {
          finalFilter[name] = '';
        } else if (
          _userValue[name].type === filterTypes.is ||
          _userValue[name].typeQuery
        ) {
          finalFilter[name] = _userValue[name].value.join(',');
        } else {
          let notSelected = _userValue[name].options?.filter(
            x =>
              x.value !== -1 &&
              x.value &&
              !_userValue[name].value.includes(x.value),
          );
          notSelected = notSelected.map(x => x.value);
          finalFilter[name] = notSelected.join(',');
        }
      } else if (
        _userValue[name].type === filterTypes.is ||
        _userValue[name].typeQuery
      ) {
        finalFilter[name] = _userValue[name].value;
      } else if (!_userValue[name].typeQuery) {
        let notSelected = _userValue[name].options?.filter(
          x => x.value !== -1 && x.value && _userValue[name].value !== x.value,
        );
        notSelected = notSelected?.map(x => x.value);
        finalFilter[name] = notSelected?.join(',');
      }
      if (_userValue[name].typeQuery) {
        finalFilter[_userValue[name].typeQuery] = _userValue[name].type;
      }
    });
    setLocalFilter(finalFilter ? JSON.stringify(finalFilter) : null);
    setActiveIndex(-1);
    setOpen(false);
  }, [finalValue]);

  useEffect(() => {
    setUserValue(finalValue);
  }, [finalValue]);

  useEffect(() => {
    if (open) setUserValue(finalValue);
  }, [open]);

  useEffect(() => {
    const newUserValue = {};
    filters?.forEach((filter, indexOfFilter) => {
      let value = '';
      let toShow = '';
      if (filter.multiple) {
        value = [];
      }
      if (filter.defaultValue) {
        value = filter.defaultValue;
        if (filter.multiple) {
          const arr = [];
          if (!Array.isArray(value)) {
            value = value.split(',');
          }
          value.forEach(dValue => {
            if (CommonUtility.isValidArray(filter?.options)) {
              const opt = filter.options.find(x => x.value === dValue);
              if (opt) {
                arr.push(opt.text);
              }
            }
          });
          toShow = arr;
        } else if (filter.options?.length > 0) {
          const opt = filter.options.find(x => x.value === filter.defaultValue);
          toShow = opt?.text;
        } else {
          toShow = value;
        }
      }

      if (filter.skip) toShow = '';

      newUserValue[filter.name] = {
        value,
        type: filterTypes.is,
        options: filter.options,
        filterType: filter.type,
        typeQuery: filter.typeQuery,
        toShow,
        skip: !!filter.skip,
        index: indexOfFilter,
      };
      if (filter.defaultQuery) {
        newUserValue[filter.name].type = filter.defaultQuery;
      }
    });

    setUserValue(newUserValue);
    setFinalvalue(newUserValue);
  }, [filters]);

  const closeDropdown = () => {
    closeSelection();
    setOpen(false);
  };

  const ref = useRef(null);
  useOutsideAlerter(ref, closeDropdown);

  const closeSelection = () => {
    setUserValue(finalValue);
    setActiveIndex(-1);
  };

  const openDropdown = () => {
    setOpen(true);
  };

  const updateFilter = (key, value) => {
    const data = {
      ...userValue,
      [key]: value,
    };
    setUserValue(data);
    setFinalvalue(data);
  };

  const updateMultiple = (name, obj) => {
    setUserValue({
      ...userValue,
      [name]: {
        ...userValue[name],
        ...obj,
      },
    });
  };

  const clearFilter = item => {
    if (item.multiple) {
      updateFilter(item.name, { value: [], type: filterTypes.is });
    } else {
      updateFilter(item.name, { value: '', type: filterTypes.is });
    }
  };
  const clearAllFilters = () => {
    const newUserValue = { ...finalValue };
    filters?.forEach(filter => {
      if (!filter.skip) {
        let value = '';
        if (filter.multiple) {
          value = [];
        }
        if (filter.must) {
          value = filter.defaultValue;
        }
        newUserValue[filter.name] = {
          value,
          type: filterTypes.is,
          options: filter.options,
          filterType: filter.type,
          typeQuery: filter.typeQuery,
          skip: !!filter.skip,
        };
      }
    });
    setUserValue(newUserValue);
    setFinalvalue(newUserValue);
  };

  const numberOfFilters = useMemo(() => {
    let totalFilters = 0;
    Object.keys(finalValue || {}).forEach(key => {
      if (!finalValue[key].skip && Array.isArray(finalValue[key].value || '')) {
        if (finalValue[key].value.length > 0) {
          totalFilters += 1;
        }
      } else if (finalValue[key].value && !finalValue[key].skip) {
        totalFilters += 1;
      }
    });
    return totalFilters;
  }, [finalValue]);

  return (
    <>
      {open ? (
        <>
          {activeIndex === -1 ? (
            <SelectionContainer ref={ref}>
              <div className="p-3 d-flex justify-content-between">
                <h4 className="m-0">Filter By</h4>
                <MuteText onClick={() => clearAllFilters()} className="pointer">
                  Clear Filters
                </MuteText>
              </div>
              {filters?.map((filter, i) => (
                <>
                  {filter.skip ? (
                    <></>
                  ) : (
                    <>
                      <Border key={`${filter.name}-border`} />
                      <Filter
                        userValue={userValue}
                        key={filter.name}
                        item={filter}
                        setActive={() => setActiveIndex(i)}
                      />
                    </>
                  )}
                </>
              ))}
            </SelectionContainer>
          ) : (
            filters?.map((filter, i) => (
              <ActiveFilter
                key={filter.name}
                applyFilter={applyFilter}
                clearFilter={() => clearFilter(filter)}
                close={closeSelection}
                closeDropdown={closeDropdown}
                filter={userValue[filter.name] || {}}
                filterOptions={filterOptions}
                index={i}
                isActive={i === activeIndex}
                item={filter}
                updateMultiple={obj => updateMultiple(filter.name, obj)}
              />
            ))
          )}
        </>
      ) : titleBtn ? (
        <FilterTitleButton
          className="flex-btn"
          loading={loading}
          onClick={openDropdown}
          size="mini"
        >
          Filters
          {numberOfFilters ? (
            <>
              <MuteText className="mx-1">({numberOfFilters})</MuteText>
              <CancelButton onClick={() => clearAllFilters()} size={12} />
            </>
          ) : (
            <></>
          )}
        </FilterTitleButton>
      ) : (
        <FilterHoverButton
          className="flex-btn"
          loading={loading}
          onClick={openDropdown}
        >
          Filters
          {numberOfFilters ? (
            <>
              <MuteText>&nbsp; ({numberOfFilters})&nbsp;&nbsp;</MuteText>
              <CancelButton onClick={() => clearAllFilters()} size={16} />
            </>
          ) : (
            <></>
          )}
        </FilterHoverButton>
      )}
    </>
  );
}

const Option = styled.div`
  border-radius: 5px;
  max-width: 100%;
  overflow: hidden;
  &:hover {
    background: ${({ theme }) => theme.colors.box};
  }
  &.active {
    background: ${({ theme }) => theme.colors.box};
    span {
      font-weight: bold;
    }
  }
  .hide {
    opacity: 0;
  }
  .show {
    opacity: 1;
  }
`;

function Filter({ item, setActive, userValue }) {
  const selectedFilters = useMemo(() => {
    if (item.multiple) {
      return userValue[item.name]?.value?.length;
    }
    if (userValue[item.name]?.value) {
      return 1;
    }
    return 0;
  }, [userValue, item]);

  return (
    <Option
      onClick={() => setActive()}
      className="pointer m-2 p-2 d-flex justify-content-between"
    >
      <div className="d-flex align-items-center">
        {isValidElement(item.icon) &&
          cloneElement(item.icon, {
            className: 'mr-2',
            size: 20,
          })}
        <span>{item.label}</span>
      </div>
      {selectedFilters ? <MuteText>{selectedFilters}</MuteText> : <></>}
    </Option>
  );
}

const ActiveFilterContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  min-width: 250px;
  box-shadow: 0px 5px 25px rgba(57, 57, 72, 0.05);
  border-radius: 5px;
  .pointer {
    cursor: pointer;
  }
`;

const DropdownIcon = styled(CaretDown)`
  margin-bottom: -3px;
  margin-left: 3px;
`;

const FilterHeader = styled.div`
  font-size: 15px;
`;

const OptionsContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  max-width: 300px;
`;

const InputContainer = styled.div`
  input {
    outline: none;
    border: none;
    flex: 1;
    max-width: 100%;
    margin: 7px;
    min-width: 0px;
  }

  &.double {
    max-width: 45%;
  }

  &:focus-within {
    border-color: black !important;
  }
  border: 1px solid ${({ theme }) => theme.colors.grey};
  border-radius: 4px;
  display: flex;
  margin-bottom: 5px;
  max-width: 300px;
  flex-wrap: wrap;
  flex: 1;
`;

const InputsContainer = styled.div`
  max-width: 300px;
  display: flex;
  column-gap: 5px;
  align-items: center;
  justify-content: space-between;
`;

function ActiveFilter({
  item,
  updateMultiple,
  applyFilter,
  filterOptions,
  isActive,
  close,
  filter,
  closeDropdown,
  clearFilter,
  index,
}) {
  const [filteredOptions, setFilteredOptions] = useState([]);

  const isOptionActive = optionValue => {
    if (item.multiple) {
      return (filter.value || []).includes(optionValue);
    }
    return optionValue === filter.value;
  };

  const updateType = value => {
    updateMultiple({
      value: value === 'BETWEEN' ? ['', ''] : '',
      toShow: '',
      type: value,
      index,
    });
  };

  const [search, setSearch] = useState('');

  const ref = useRef(null);
  useOutsideAlerter(ref, closeDropdown);

  const setOption = option => {
    const { value, text } = option;
    if (item.multiple) {
      const prevValue = filter.value || [];
      const prevShow = filter.toShow || [];

      if (prevValue.includes(value)) {
        const toSet = prevValue.filter(x => x !== value);
        const toSetText = prevShow.filter(x => x !== text);
        updateMultiple({
          value: toSet,
          toShow: toSetText,
          index,
        });
      } else if (value === -1 || !value) {
        updateMultiple({
          value: [value],
          toShow: [text],
          index,
        });
      } else {
        updateMultiple({
          value: [...prevValue, value],
          toShow: [...prevShow, text],
          index,
        });
      }
    } else if (filter?.value?.toString().includes(value)) {
      updateMultiple({
        value: '',
        toShow: '',
        index,
      });
    } else {
      updateMultiple({
        value,
        toShow: text,
        index,
      });
    }
  };

  useEffect(() => {
    if (search) {
      let arr = [];
      if (CommonUtility.isFunction(item.customSearch)) {
        arr = item.options?.filter(x => item.customSearch(x, search));
      } else {
        arr = item.options?.filter(x =>
          x.text.toLowerCase()?.includes(search.toLowerCase()),
        );
      }
      arr = arr.sort((option1, option2) => {
        const re = new RegExp(`^${search}`, 'i');
        return re.test(option1.value)
          ? re.test(option2.value)
            ? option1.value.localeCompare(option2.value)
            : -1
          : 1;
      });
      setFilteredOptions(arr);
    } else {
      setFilteredOptions(item.options);
    }
  }, [search]);

  const isBetween = useMemo(() => filter.type === 'BETWEEN', [filter.type]);

  if (!isActive) return <></>;

  if (item.type === 'text') {
    return (
      <ActiveFilterContainer className="p-3" ref={ref}>
        <div className="d-flex align-items-center justify-content-between">
          <FilterHeader className="d-flex align-items-center">
            <MuteText>{item.label}</MuteText>
            <Dropdown
              className="ml-2"
              options={item.custom_options || filterOptions}
              inline
              value={filter.type}
              icon={<DropdownIcon />}
              onChange={(e, { value }) => updateType(value)}
            />
          </FilterHeader>
          <MuteText className="pointer" onClick={() => clearFilter()}>
            Clear Filter
          </MuteText>
        </div>
        <Border className="my-3" />
        <InputsContainer>
          <InputContainer className={isBetween && 'double'}>
            {isValidElement(item.prefix) && cloneElement(item.prefix)}
            {isBetween || item.numeric ? (
              <NumberMaskInput
                maxValue={9999999999}
                placeholder={
                  isBetween
                    ? 'From'
                    : item.placeholder
                    ? item.placeholder
                    : `Search for ${item.label}`
                }
                value={isBetween ? filter.value[0] : filter.value}
                onChange={e =>
                  setOption({
                    value: isBetween ? [e, filter.value[1]] : e,
                    text: isBetween ? (
                      <span className="d-flex align-items-center">
                        {e} <ArrowsHorizontal className="mx-1" size={10} />{' '}
                        {filter.value[1]}
                      </span>
                    ) : (
                      e
                    ),
                  })
                }
              />
            ) : (
              <input
                placeholder={`Search for ${item.label}`}
                value={filter.value}
                onChange={e =>
                  setOption({
                    value: e.target.value,
                    text: e.target.value,
                  })
                }
              />
            )}
          </InputContainer>
          {isBetween && (
            <>
              <span>-</span>
              <InputContainer className="double">
                {isValidElement(item.prefix) && cloneElement(item.prefix)}
                <NumberMaskInput
                  placeholder="To"
                  value={filter.value[1]}
                  onChange={e =>
                    setOption({
                      value: [filter.value[0], e],
                      text: (
                        <span className="d-flex align-items-center">
                          {filter.value[0]}{' '}
                          <ArrowsHorizontal className="mx-1" size={10} /> {e}
                        </span>
                      ),
                    })
                  }
                />
              </InputContainer>
            </>
          )}
        </InputsContainer>
        <div className="d-flex w-100 mt-4">
          <StyledButton
            className="flex-grow-1"
            onClick={() => {
              applyFilter();
            }}
          >
            Apply
          </StyledButton>
          <HoverBorderButton onClick={() => close()} className="flex-grow-1">
            Cancel
          </HoverBorderButton>
        </div>
      </ActiveFilterContainer>
    );
  }

  if (item.type === 'date') {
    return (
      <ActiveFilterContainer className="p-3" ref={ref}>
        <div className="d-flex align-items-center justify-content-between">
          <FilterHeader className="d-flex align-items-center">
            <MuteText>{item.label}</MuteText>
            <Dropdown
              className="ml-2"
              options={item.custom_options || filterOptions}
              inline
              value={filter.type}
              icon={<DropdownIcon />}
              onChange={(e, { value }) => updateType(value)}
            />
          </FilterHeader>
          <MuteText className="pointer" onClick={() => clearFilter()}>
            Clear Filter
          </MuteText>
        </div>
        <Border className="my-3" />
        <DatepickerContainer>
          {isBetween && (
            <DateRangeInputContainer>
              <CustomDateInput label="Start" date={filter.value[0]} />
              <CustomDateInput label="End" date={filter.value[1]} />
            </DateRangeInputContainer>
          )}
          <ReactDatePicker
            startOpen
            inline
            selectsRange={!!isBetween}
            startDate={
              isBetween
                ? filter.value[0] && new Date(filter.value[0])
                : filter.value
            }
            endDate={
              isBetween
                ? filter.value[1] && new Date(filter.value[1])
                : filter.value
            }
            onChange={update => {
              setOption({
                value: update,
                text: isBetween ? (
                  <span className="d-flex align-items-center">
                    {DateUtility.formatDate(
                      update[0],
                      DateFormat.yearMonthDate,
                    )}
                    <ArrowsHorizontal className="mx-1" size={10} />
                    {DateUtility.formatDate(
                      update[1],
                      DateFormat.yearMonthDate,
                    )}
                  </span>
                ) : (
                  DateUtility.formatDate(update, DateFormat.yearMonthDate)
                ),
              });
            }}
          />
        </DatepickerContainer>
        <div className="d-flex w-100 mt-4">
          <StyledButton
            className="flex-grow-1"
            onClick={() => {
              applyFilter();
            }}
          >
            Apply
          </StyledButton>
          <HoverBorderButton onClick={() => close()} className="flex-grow-1">
            Cancel
          </HoverBorderButton>
        </div>
      </ActiveFilterContainer>
    );
  }

  const sortedOptions = useMemo(
    () =>
      item.sorted === false
        ? filteredOptions
        : CommonUtility.sortDropdownOptions(filteredOptions),
    [filteredOptions],
  );

  return (
    <ActiveFilterContainer className="p-3" ref={ref}>
      <div className="d-flex align-items-center justify-content-between">
        <FilterHeader className="d-flex align-items-center">
          <MuteText>{item.label}</MuteText>
          <Dropdown
            className="ml-2"
            options={item.custom_options || filterOptions}
            inline
            value={filter.type}
            icon={<DropdownIcon />}
            onChange={(e, { value }) => updateType(value)}
          />
        </FilterHeader>
        <MuteText className="pointer" onClick={() => clearFilter()}>
          Clear Filter
        </MuteText>
      </div>
      <Border className="my-3" />
      {(item.search || item.multiple) && (
        <InputContainer>
          <input
            className={`w-100 ${
              (filter.value || []).length !== 0 && item.multiple && 'small'
            }`}
            placeholder="Search for one or more options..."
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </InputContainer>
      )}
      {sortedOptions.length > 0 ? (
        <OptionsContainer autoHide>
          {sortedOptions.map(option => (
            <OptionRenderer
              key={option.value}
              option={option}
              countryFlag={option.flag || option.item?.region}
              filter={filter}
              isOptionActive={isOptionActive}
              setOption={setOption}
            />
          ))}
        </OptionsContainer>
      ) : (
        'No Options found'
      )}
      <div className="d-flex w-100 mt-4">
        <StyledButton
          className="flex-grow-1"
          onClick={() => {
            applyFilter();
          }}
        >
          Apply
        </StyledButton>
        <HoverBorderButton onClick={() => close()} className="flex-grow-1">
          Cancel
        </HoverBorderButton>
      </div>
    </ActiveFilterContainer>
  );
}

const StyledImage = styled.img`
  width: 25px;
  height: 25px;
  object-fit: contain;
`;

function OptionRenderer({
  isOptionActive,
  option,
  filter,
  setOption,
  countryFlag,
}) {
  const active = useMemo(() => isOptionActive(option.value), [filter, option]);

  return (
    <Option
      onClick={() => setOption(option)}
      className={`d-flex justify-content-between pointer m-2 p-2 align-items-center ${
        active && 'active'
      }`}
    >
      {isValidElement(option.content) ? (
        cloneElement(option.content)
      ) : (
        <div className="d-flex align-items-center">
          {countryFlag && <Flag name={countryFlag} />}
          {option?.image?.src && (
            <StyledImage className="mr-2" src={option?.image?.src} />
          )}
          <span>{option.text}</span>
        </div>
      )}
      <Check className={active ? 'show' : 'hide'} size={18} />
    </Option>
  );
}

const Pill = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 3px 7px;
  font-size: 10px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey};
  .pointer {
    cursor: pointer;
  }

  .text-container {
    max-width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.box};
  }
`;

export function FilterPill({
  data,
  userValue,
  removeFilter,
  openRelevantFilter,
}) {
  const toShow = userValue[data.name]?.toShow;
  const text = useMemo(() => {
    if (Array.isArray(toShow)) {
      if (toShow.length > 0) {
        if (toShow.length > 2) {
          return `${toShow.splice(0, 2).join(',')}...`;
        }
        return toShow.join(',');
      }
      return '';
    }
    return toShow;
  }, [toShow]);

  const index = useMemo(() => userValue[data.name]?.index, [data, userValue]);

  const imageToRender = useMemo(() => {
    if (data.custom_options) {
      const selectedOption = data.custom_options.find(
        x => x.value === userValue[data.name]?.type,
      );
      if (selectedOption) {
        return selectedOption.conditionImage;
      }
    } else {
      const selectedOption = filterOptions.find(
        x => x.value === userValue[data.name]?.type,
      );
      if (selectedOption) {
        return selectedOption.conditionImage;
      }
    }
    return null;
  }, [data, userValue]);

  if (text) {
    return (
      <Pill className="ml-2">
        {isValidElement(data.icon) &&
          cloneElement(data.icon, {
            className: 'mr-2 pointer',
            size: 12,
            onClick: () => openRelevantFilter(index),
          })}
        <MuteText className="pointer" onClick={() => openRelevantFilter(index)}>
          {data.label}&nbsp;
        </MuteText>
        {isValidElement(imageToRender) &&
          cloneElement(imageToRender, {
            className: 'pointer',
            onClick: () => openRelevantFilter(index),
          })}
        &nbsp;
        <span
          onClick={() => openRelevantFilter(index)}
          className="text-container pointer"
        >
          {isValidElement(text) ? cloneElement(text) : text}
        </span>
        <X
          onClick={() => removeFilter(data.name)}
          className="ml-1 pointer cursor-pointer"
        />
      </Pill>
    );
  }
  return <></>;
}
