import { useEffect } from 'react';

export const useBarcodeScanner = onScanBarcode => {
  useEffect(() => {
    let scannedBarcode = '';

    const handleKeyDown = event => {
      if (event.key === 'Enter') {
        onScanBarcode(scannedBarcode); // Update state with scanned value
        scannedBarcode = ''; // Reset
      } else if (event.key?.length === 1) {
        scannedBarcode += event.key;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);
};
