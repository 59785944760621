import { useMemo } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from 'semantic-ui-react';
import styled from 'styled-components';
import { CommonUtility, Tabs } from '../../../../utility';
import { WaveRulesTab } from './wave-rules';
import { TotesTab } from './totes';
import { PalletsTab } from './pallets';

const MenuContainer = styled.div`
  margin-top: -10px;
`;

export function FulfillmentTab({ warehouseId }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const currentTab = useMemo(() => {
    if (location.pathname) {
      return (
        CommonUtility.getSegmentByPosition(location.pathname, 5) ||
        Tabs.settingsTab.fulfillmentTabs.waveRules
      );
    }
    return null;
  }, [pathname]);

  const tabClicked = tabName => {
    navigate(`/app/settings/fulfillment/${warehouseId}/${tabName}`);
  };

  const menus = [
    {
      text: 'Wave Rules',
      value: Tabs.settingsTab.fulfillmentTabs.waveRules,
    },
    {
      text: 'Totes',
      value: Tabs.settingsTab.fulfillmentTabs.totes,
    },
    {
      text: 'Pallets',
      value: Tabs.settingsTab.fulfillmentTabs.pallets,
    },
  ];

  return (
    <div>
      <MenuContainer className="d-flex justify-content-between align-items-center mt-4 pt-2">
        <Menu pointing secondary className="m-0">
          {menus.map(menu => (
            <MenuItem
              name={menu.text}
              key={menu.value}
              active={currentTab === menu.value}
              onClick={() => tabClicked(menu.value)}
            />
          ))}
        </Menu>
      </MenuContainer>
      <Routes>
        <Route
          path=":id"
          element={<WaveRulesTab warehouseId={warehouseId} />}
        />
        <Route
          path={`:id/${Tabs.settingsTab.fulfillmentTabs.waveRules}`}
          element={<WaveRulesTab warehouseId={warehouseId} />}
        />
        <Route
          path={`:id/${Tabs.settingsTab.fulfillmentTabs.totes}`}
          element={<TotesTab warehouseId={warehouseId} />}
        />
        <Route
          path={`:id/${Tabs.settingsTab.fulfillmentTabs.pallets}`}
          element={<PalletsTab warehouseId={warehouseId} />}
        />
      </Routes>
    </div>
  );
}
