import { useMemo, useState } from 'react';
import { CalendarX } from '@phosphor-icons/react';
import { AppTable } from '../../../components/AppTable';
import {
  BodyContainer,
  HeaderBar,
  PageTitle,
  TitleBarTitle,
  TitleHeaderBar,
} from '../../../elements';
import { InventoryListHook } from '../../../hooks/inventory';
import {
  InventoryExpirationPopup,
  InventoryExpirationRow,
} from '../../../page-components';
import { CommonUtility } from '../../../utility';
import { WarehouseListHook } from '../../../hooks';
import { WarehouseDropdown } from '../../../components';
import { DesktopMode, MobileMode } from '../../../layouts/ResponsiveMedia';

function Header({
  warehouseOptions,
  warehouseLoading,
  warehouseId,
  setWarehouseId,
  defaultWarehouse,
}) {
  return (
    <>
      <MobileMode>
        <HeaderBar className="row">
          <div className="d-flex flex-column col">
            <PageTitle className="d-flex align-items-center">
              <CalendarX size={25} />
              <span className="mx-2">Expirations for</span>
            </PageTitle>
            <div className="d-flex align-items-center ml-2">
              <WarehouseDropdown
                warehouseOptions={warehouseOptions}
                warehouseId={warehouseId}
                warehouseLoading={warehouseLoading}
                setWarehouseId={setWarehouseId}
                defaultWarehouseId={defaultWarehouse}
                className="mb-1"
              />
            </div>
          </div>
        </HeaderBar>
      </MobileMode>
      <DesktopMode>
        <TitleHeaderBar className="row">
          <div className="d-flex col-8 align-items-center">
            <TitleBarTitle className="d-flex align-items-center">
              <CalendarX size={20} className="mr-1 icon" />{' '}
              <span>Expirations for</span>
            </TitleBarTitle>
            <div className="d-flex align-items-center ml-2">
              <WarehouseDropdown
                warehouseOptions={warehouseOptions}
                warehouseId={warehouseId}
                warehouseLoading={warehouseLoading}
                setWarehouseId={setWarehouseId}
                defaultWarehouseId={defaultWarehouse}
                titleMode
              />
            </div>
          </div>
        </TitleHeaderBar>
      </DesktopMode>
    </>
  );
}

export function ExpirationListScreen() {
  const [adjustmentItem, setAdjusmentItem] = useState(null);
  const [warehouseId, setWarehouseId] = useState('');

  const { data: warehouseData, loading: warehouseLoading } =
    WarehouseListHook();

  const warehouseOptions = useMemo(() => {
    if (CommonUtility.isValidArray(warehouseData)) {
      return warehouseData?.map(wh => ({
        text: wh?.name,
        key: wh?._id,
        value: wh?._id,
      }));
    }
    return [];
  }, [warehouseData]);

  const openAdjustmentPopup = item => {
    setAdjusmentItem(item);
  };

  const closeAdjustmentPopup = () => {
    setAdjusmentItem(null);
  };

  const {
    data,
    loading,
    totalData,
    totalPages,
    filter,
    filterChanged,
    pageChanged,
    refresh,
  } = InventoryListHook(warehouseId, true);

  const columns = [
    {
      text: 'Product Description',
      key: 'description',
      width: 3,
      textAlign: 'left',
    },
    {
      text: 'Location',
      key: 'location',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Expiry Date',
      key: 'expiry_date',
      width: 2,
      textAlign: 'center',
    },
    {
      text: 'Units Expiring',
      key: 'units_expiring',
      width: 1,
      textAlign: 'left',
    },
    {
      text: 'Lots',
      key: 'lots',
      width: 2,
      textAlign: 'left',
    },
    {
      text: '',
      key: 'action',
      width: 3,
      textAlign: 'left',
    },
  ];

  const defaultWarehouse = useMemo(
    () =>
      CommonUtility.isValidArray(warehouseData) ? warehouseData[0]._id : null,
    [warehouseData],
  );

  return (
    <BodyContainer className="no-header">
      <Header
        setWarehouseId={setWarehouseId}
        warehouseId={warehouseId}
        warehouseOptions={warehouseOptions}
        warehouseLoading={warehouseLoading}
        defaultWarehouse={defaultWarehouse}
      />
      <AppTable
        columns={columns}
        data={data}
        loading={loading}
        totalData={totalData}
        totalPages={totalPages}
        RowComponent={
          <InventoryExpirationRow openPopup={openAdjustmentPopup} />
        }
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        headerOffSet={0}
      />
      <InventoryExpirationPopup
        onClose={closeAdjustmentPopup}
        open={!!adjustmentItem}
        item={adjustmentItem}
        refresh={refresh}
        warehouseId={warehouseId}
      />
    </BodyContainer>
  );
}
