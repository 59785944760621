import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { AppTable } from '../../../../../components/AppTable';
import { WaveListHook } from '../../../../../hooks';
import { WaveRow } from '../../../../../page-components';
import { CommonUtility } from '../../../../../utility';
import { useWavesFilterContext } from '.';

export function WaveListTab() {
  const { pathname } = useLocation();

  const status = useMemo(() => {
    if (location.pathname) {
      return CommonUtility.getSegmentByPosition(location.pathname, 5);
    }
    return null;
  }, [pathname]);

  const { warehouseId, search } = useWavesFilterContext();

  const {
    data,
    totalData,
    totalPages,
    loading,
    filter,
    filterChanged,
    pageChanged,
  } = WaveListHook(status, warehouseId, search);

  const columns = [
    {
      text: 'Wave Number',
      key: 'wave-number',
      width: 2,
      textAlign: 'left',
    },
    {
      text: 'Status',
      key: 'status',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Created',
      key: 'created',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Warehouse',
      key: 'warehouse',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Rule',
      key: 'rule',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Picker',
      key: 'picker',
      textAlign: 'left',
      width: 2,
    },
    {
      text: 'Contents',
      textAlign: 'left',
      key: 'details',
      width: 3,
    },
  ];

  return (
    <div>
      <AppTable
        loading={loading}
        columns={columns}
        data={data || []}
        totalData={totalData}
        totalPages={totalPages}
        currentPage={filter.page}
        pageSizeChanged={size => filterChanged('limit', size)}
        pageSize={filter.limit}
        pageChanged={pageChanged}
        RowComponent={<WaveRow />}
        headerOffSet={37}
      />
    </div>
  );
}
